/* eslint-disable arrow-parens,arrow-body-style,react/prop-types */
import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LogInForm from './components/LogInForm';
import RegistrationForm from './components/RegistrationForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import { loginUser, signUpUser, clearUserAlert, resendVerificationEmail} from '../../redux/actions/users';
import { Alert, Button } from 'reactstrap';
import {setWhiteLabel} from '../../redux/actions/whiteLabel';
// import ReactGA from 'react-ga';
import welomeLogo from '../../shared/img/logo/welcome-banner-logo.svg'
import { TrackGoogleAnalyticsEvent } from '../../shared/utils/google-analytics';
import GoogleButton from '../../shared/GoogleButton';
import Cookies from 'js-cookie';
import { getCurrentDashboardViewFromStorage } from '../../redux/actions/preferences';


function LogIn(props) {

  const [ formType, setFormType ] = React.useState(props.match.path.substring(1) || 'login');
  const [ invitationId, setInvitationId ] = React.useState('');

  let apiUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:3777';

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const invitationId = queryParameters.get('invitationId')
    const linkPreview = queryParameters.get('link-preview-tool')

    if (invitationId) {
      setInvitationId(invitationId);
    }
    if (linkPreview) {
      Cookies.set('dashBoardView', 'marketing', { expires: 365 });
    }
  }, []);

  useEffect(() => {
    if(props.whiteLabel.loading) {
      props.setWhiteLabel(window.location.hostname);
    } else {
      redirectIfLoggedIn();
    }
  }, [props.whiteLabel]);

  useEffect(() => {
    if(props.match.path === '') {
      debugger
      setFormType('login');
    }
  }, []);

  useEffect(() => {
    if(props.user) {
      redirectIfLoggedIn();
    }
  }, [props.user]);

  const redirectIfLoggedIn = () => {
    if(props.user) {
      // Check localStorage first, then fall back to Redux state
      const storedView = getCurrentDashboardViewFromStorage();
      const currentView = storedView || props.preferences.dashboard.view;
      
      if (currentView === 'MARKETER') {
        props.history.push('/link-preview');
      } else if (currentView === 'developer') {
        props.history.push('/apis/Opengraphio');
      } else if(props.whiteLabel.whiteLabelMode) {
        props.history.push(`/apis/${props.whiteLabel.whiteLabelApi.uriFriendlyName}`)
      }
    }
  }

  const signInToGoogle = () => {
    TrackGoogleAnalyticsEvent('googleSignInOrSignUp', 'Sign Up Flow', 'googleSignInOrSignUp', 'login_register')
  }

  const handleSignInButtonClick = () => {
    TrackGoogleAnalyticsEvent('googleSignInOrSignUp', 'Sign Up Flow', 'googleSignInOrSignUp', 'login_register')
    window.location.href = apiUrl + '/auth/google';
  };

  const signUpUser = () => {
    props.signUpUser()
  }

  const switchFormClicked = () => {
    switch (formType) {
      case 'login':
        props.history.push('/register');
        setFormType('register');
        break;
      case 'register':
        props.history.push('/login');
        setFormType('login');
        break;
      default:
        props.history.push('/login');
        setFormType('login');
        break;
    }
  };

  const renderForm = () => {
    if(formType === 'register'){
      return <RegistrationForm invitationId={invitationId} flipForm={switchFormClicked}/>
    } else if (formType === 'resetpassword'){
      return <ResetPasswordForm loginUser={props.loginUser} signUpUser={signUpUser} formData={props.formData} />
    } else {
      return <LogInForm loginUser={props.loginUser} signUpUser={signUpUser} formData={props.formData} history={props.history} switchToPasswordResetForm={switchToPasswordResetForm}/>
    }
  }

  const renderHeadOfForm = () => {
    if(props.whiteLabel.whiteLabelMode){
      return (
        <div>
          <h3 className="account__profile">
            <span className="account__logo">OpenGraph
                  <span className="account__logo-accent">.</span>
                  <span>io</span>
                </span>
          </h3>
        </div>
      )
    } else {
      return (
        <div className="account__head">
          <h3 className="account__title">Welcome to
            <span className="account__logo"> API
                  <span className="account__logo-accent">FUR</span>
                </span>
          </h3>
          <h4 className="account__subhead subhead">Come in and REST your feet.</h4>
        </div>
      )
    }
  };

  const switchToPasswordResetForm = () => {
    setFormType('resetpassword')
  }

  return (
    <div className="account">
      <div className="account__wrapper row">
        <div className="account__card col">
          <div className="account__card-container">
            {props.alert ? <Alert color={props.alert.color} className="alert--bordered" isOpen={!!props.alert.message} toggle={props.clearUserError}>
              <div className={'padding5'}>
                <span>{props.alert.message}</span>
                {props.alert.message === 'Please Verify Email.' &&
                  <span className={'alert-link clickable'} onClick={props.resendVerificationEmail}> Resend Email</span>}
              </div>
            </Alert> : null}
            {renderHeadOfForm()}
            {renderForm()}
            <Button color={'link'} siz={'sm'} className="btn btn-outline-primary account__btn account__btn--small" onClick={switchFormClicked}>{formType === 'login' ? 'Register' : 'Back To Sign In'  }</Button>
            <div className="account__or">
              <p>OR</p>
            </div>
            <div style={{ width: '100%'}}>
              <button style={{ border: 'none', width: '100%', background: 'none', padding: 0}} onClick={handleSignInButtonClick}>
                <GoogleButton />
              </button>
            </div>
          </div>
        </div>
        <div className={'account__welcome col'}>
          <div className={'account__welcome-container'}>
            <div className={'account__welcome-title'}>
              <h3 className="account__welcome-logo">
                <span className="account__logo-accent">Welcome </span>
                <span>to Open Graph API</span>
              </h3>
              <p>A very simple Open Graph API</p>
            </div>
            <img src={welomeLogo} />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
    organization: state.organization.currentOrg,
    formData: state.form.log_in_form,
    alert: state.users.alert,
    whiteLabel: state.whiteLabel,
    preferences: state.preferences
  };
};

const mapDispatchToProps = (dispatch) => {
  return ({
    loginUser: (payload) => dispatch(loginUser(payload)),
    signUpUser: (payload) => dispatch(signUpUser(payload)),
    clearUserError: () => dispatch(clearUserAlert()),
    resendVerificationEmail: () => dispatch(resendVerificationEmail()),
    setWhiteLabel: (payload) => dispatch(setWhiteLabel(payload))
  });
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogIn));

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
