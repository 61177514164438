import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { TrackGoogleAnalyticsEvent } from '../../../utils/google-analytics'
import './CancelSubscriptionModal.scss';

const CancelSubscriptionModal = ({ isOpen, toggle, plan, tiers, defaultTierId, onCancelSubscription }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [cancelReason, setCancelReason] = useState('');
  const [otherReason, setOtherReason] = useState('');
  const [expensiveReason, setExpensiveReason] = useState('');
  const [followUpText, setFollowUpText] = useState('');
  const [defaultTier, setDefaultTier] = useState(null);

  const cancelReasons = [
    'We intend to use a different tool',
    'We do not have a business need',
    'Too expensive',
    'Missing features I need',
    'Technical issues',
    'Poor customer support',
    'Other'
  ];

  const handleCancelReasonChange = (e) => {
    const selectedReason = e.target.value;
    setCancelReason(selectedReason);
    
    // Track the selected cancellation reason
    TrackGoogleAnalyticsEvent(
      'cancellation_reason_selected',
      'Cancellation',
      'Selected Reason',
      selectedReason
    );
  };

  const handleSelectReason = (reason) => {
    setCancelReason(reason);
    
    // Track the selected cancellation reason
    TrackGoogleAnalyticsEvent(
      'cancellation_reason_selected',
      'Cancellation',
      'Selected Reason',
      reason
    );
  };

  const handleOtherReasonChange = (e) => {
    setOtherReason(e.target.value);
  };

  const handleExpensiveReasonChange = (e) => {
    setExpensiveReason(e.target.value);
  };

  const handleFollowUpTextChange = (e) => {
    setFollowUpText(e.target.value);
  };

  const handleNextStep = () => {
    if (currentStep === 1) {
      // Always go to step 2 with prompt based on selected reason
      TrackGoogleAnalyticsEvent(
        'cancellation_flow_step',
        'Cancellation',
        'Moved to Step',
        'Step 2'
      );
      setCurrentStep(2);
    } else if (currentStep === 2) {
      // From step 2, go to confirmation
      TrackGoogleAnalyticsEvent(
        'cancellation_flow_step',
        'Cancellation',
        'Moved to Step',
        'Step 3'
      );
      setCurrentStep(3);
    }
  };

  const handlePrevStep = () => {
    if (currentStep === 3) {
      // From confirmation to step 2
      TrackGoogleAnalyticsEvent(
        'cancellation_flow_step',
        'Cancellation',
        'Moved to Step',
        'Step 2'
      );
      setCurrentStep(2);
    } else if (currentStep === 2) {
      // From step 2 to step 1
      TrackGoogleAnalyticsEvent(
        'cancellation_flow_step',
        'Cancellation',
        'Moved to Step',
        'Step 1'
      );
      setCurrentStep(1);
    }
  };

  useEffect(() => {
    if(tiers) {
      const tier = tiers.find(tier => tier.id === defaultTierId)
      setDefaultTier(tier)
    }
  }, [tiers])

  const resetForm = () => {
    setCurrentStep(1);
    setCancelReason('');
    setOtherReason('');
    setExpensiveReason('');
    setFollowUpText('');
  };

  const handleCancelSubscription = () => {
    setIsProcessing(true);
    
    // Collect all the feedback data with simplified structure
    let explanation = '';
    
    // Determine which explanation field to use based on selected reason
    if (cancelReason === 'Other') {
      explanation = otherReason;
    } else if (cancelReason === 'Too expensive') {
      explanation = expensiveReason;
    } else {
      explanation = followUpText;
    }
    
    // Simplified feedback structure with just reason and explanation
    const feedbackData = {
      reason: cancelReason,
      explanation: explanation
    };
    
    // Track the completed cancellation with reason
    TrackGoogleAnalyticsEvent(
      'subscription_cancelled',
      'Cancellation',
      'Completed',
      cancelReason
    );
    
    // Pass the feedback data to the parent component
    if (typeof onCancelSubscription === 'function') {
      onCancelSubscription(feedbackData);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <h5 className="heading">We're sorry to see you go</h5>
            <p className="text">
              Before you cancel, we'd appreciate your feedback to help us improve our service.
            </p>
            <Form>
              <FormGroup className="form-group">
                <legend>What's your primary reason for cancelling?</legend>
                <div className="radio-group">
                  {cancelReasons.map((reason, i) => (
                    <FormGroup check key={i + 'choice.value'} className="form-check">
                      <Input
                        name="cancelReason"
                        id={reason}
                        type="radio"
                        value={reason}
                        onChange={handleCancelReasonChange}
                        checked={cancelReason === reason}
                      />
                      {' '}
                      <Label check className="form-check-label">
                        {reason}
                      </Label>
                    </FormGroup>
                  ))}
                </div>
              </FormGroup>
            </Form>
          </>
        );
      case 2:
        if (cancelReason === 'Too expensive') {
          return (
            <>
              <h5 className="heading">Tell us more about the pricing</h5>
              <p className="text">
                We'd like to understand more about your pricing concerns.
              </p>
              <Form>
                <FormGroup className="form-group">
                  <Label for="expensiveReason">What would make our pricing more suitable for you?</Label>
                  <Input
                    type="textarea"
                    name="expensiveReason"
                    id="expensiveReason"
                    rows="4"
                    value={expensiveReason}
                    onChange={handleExpensiveReasonChange}
                    placeholder="Please share your thoughts on our pricing..."
                    className="form-control"
                  />
                </FormGroup>
              </Form>
            </>
          );
        } else if (cancelReason === 'Other') {
          return (
            <>
              <h5 className="heading">Please tell us more</h5>
              <p className="text">
                We'd like to understand your specific reason for cancellation.
              </p>
              <Form>
                <FormGroup className="form-group">
                  <Label for="otherReason">Why are you cancelling?</Label>
                  <Input
                    type="textarea"
                    name="otherReason"
                    id="otherReason"
                    rows="4"
                    value={otherReason}
                    onChange={handleOtherReasonChange}
                    placeholder="Please share your specific reason for cancellation..."
                    className="form-control"
                  />
                </FormGroup>
              </Form>
            </>
          );
        } else {
          return (
            <>
              <h5 className="heading">Tell us more about why you're leaving</h5>
              <p className="text">
                You selected: <strong>{cancelReason}</strong>
              </p>
              <p className="text">
                Your detailed feedback helps us improve our product for everyone.
              </p>
              <Form>
                <FormGroup className="form-group">
                  <Label for="followUpText">Additional details</Label>
                  <Input
                    type="textarea"
                    name="followUpText"
                    id="followUpText"
                    rows="4"
                    value={followUpText}
                    onChange={handleFollowUpTextChange}
                    placeholder="Please share more details about your experience..."
                    className="form-control"
                  />
                </FormGroup>
              </Form>
            </>
          );
        }
      case 3:
        return (
          <>
            <h5 className="heading">Confirm Cancellation</h5>
            <div className="confirmation-container">
              <div className="warning-icon">
                <i className="fa fa-exclamation-circle"></i>
              </div>
              <div className="confirmation-text">
                <p>Are you sure you want to cancel your subscription?</p>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const renderFooterButtons = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <Button color="secondary" onClick={toggle}>
              Go Back
            </Button>
            <Button 
              color="primary" 
              onClick={handleNextStep} 
              disabled={!cancelReason}
            >
              Continue
            </Button>
          </>
        );
      case 2:
        if (cancelReason === 'Too expensive') {
          return (
            <>
              <Button color="secondary" onClick={handlePrevStep}>
                Previous
              </Button>
              <Button 
                color="primary" 
                onClick={handleNextStep}
                disabled={!expensiveReason.trim()}
              >
                Continue
              </Button>
            </>
          );
        } else if (cancelReason === 'Other') {
          return (
            <>
              <Button color="secondary" onClick={handlePrevStep}>
                Previous
              </Button>
              <Button 
                color="primary" 
                onClick={handleNextStep}
                disabled={!otherReason.trim()}
              >
                Continue
              </Button>
            </>
          );
        } else {
          return (
            <>
              <Button color="secondary" onClick={handlePrevStep}>
                Previous
              </Button>
              <Button 
                color="primary" 
                onClick={handleNextStep}
              >
                Continue
              </Button>
            </>
          );
        }
      case 3:
        return (
          <>
            <Button color="secondary" onClick={handlePrevStep} disabled={isProcessing}>
              Previous
            </Button>
            <Button color="danger" onClick={handleCancelSubscription} disabled={isProcessing}>
              {isProcessing ? (
                <>
                  <Spinner size="sm" /> Processing...
                </>
              ) : (
                'Confirm Cancellation'
              )}
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  const handleClose = () => {
    toggle();
    // Reset the form when closing
    resetForm();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose} className="cancel-subscription-modal">
      <ModalHeader toggle={handleClose}>
        {currentStep === 3 ? 'Cancel Subscription' : 'We Value Your Feedback'}
      </ModalHeader>
      <ModalBody>
        {renderStepContent()}
      </ModalBody>
      <ModalFooter>
        {renderFooterButtons()}
      </ModalFooter>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  plan: state.plans.detailPlan,
  tiers: state.apis.detailApi.tiers,
  defaultTierId: state.apis.detailApi.defaultTierId
});

export default connect(mapStateToProps)(CancelSubscriptionModal); 