'use strict';

var DEFAULT_URI_ENDPOINT = '';
var pagedRequestHandler = require('./pagedRequestHandler');
var superAgent =  require('superagent');

var Client;
Client = module.exports = function(params){

  if(!params) params = {};

  /*
   SUPPORTED PARAMS:
   server - Optional, defaults to blah

   debug - Defaults to false
   version - Defaults to v1
   */

  this.server = (params.server) ? params.server : DEFAULT_URI_ENDPOINT;

  this.agent = superAgent.agent();

  if(this.server.length === 0 &&  (typeof window !== 'undefined')) {
    this.server = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
  };

  this.debug = (params.debug) ? params.debug : false;
  this.version = (params.version) ? params.version : 'v1';
  this.token = params.token;
  this.disableQueryParams = (params.disableQueryParams) ? params.disableQueryParams : false;

  this.useAgent = (params.useAgent === true) ? params.useAgent : false;


  this.apiToken = params.apiToken;

  return this;
};



Client.prototype.pagedGetRequest = function(path, useRawPath){

  var uri = this.generateUriFromPath.call(this, path, useRawPath);

  var handler = new pagedRequestHandler(uri, {
    debug: this.debug,
    verb: 'GET',
    agent: this.agent,
    disableQueryParams: this.disableQueryParams,
    token: this.token,
    apiToken: this.apiToken
  });

  return handler;

}

Client.prototype.pagedRequest = function(verb, path, payload){

  var uri = this.generateUriFromPath.call(this, path);

  var pageParams = {
    debug: this.debug,
    verb: verb,
    agent: this.agent,
    disableQueryParams: this.disableQueryParams,
    token: this.token,
    apiToken: this.apiToken
  };

  if(payload){
    pageParams.payload = payload;
  }

  return new pagedRequestHandler(uri, pageParams);
}

Client.prototype.generateUriFromPath = function(path, useRawPath){


  var uri;

  if(!useRawPath || useRawPath === false){
    uri = this.server + '/api/' + this.version  + path ;
  }
  else{
    uri = this.server + path ;
  }

  if(this.testSubdomain){
    uri = uri + '?cname=' + this.testSubdomain;
  }

  return uri;

};


// User functions

Client.prototype.getUsers = function(){
  return this.pagedGetRequest('/users');
};

Client.prototype.getUser = function(id){
  return this.pagedGetRequest(`/users/${id}`);
};

Client.prototype.createUser = function(userInfo, location){
  if(location) {
    return this.pagedRequest('POST', `/users?location=${location}`, userInfo);
  }
  return this.pagedRequest('POST', `/users`, userInfo);
};

Client.prototype.updateUser = function(userInfo){
  return this.pagedRequest('PUT', `/users/me`, userInfo);
};
Client.prototype.requestPasswordReset = function(payload){
  return this.pagedRequest('POST', `/users/me/requestresetpassword`, payload);
};

Client.prototype.requestVerificationEmail = function(payload){
  return this.pagedRequest('POST', `/users/me/requestverificationemail`, payload);
};

Client.prototype.logout = function(){
  return this.pagedGetRequest('/users/me/logout');
};

Client.prototype.updateLastUsedOrganization = function(organizationId){
  return this.pagedRequest('PUT', `/users/organization/last-used-organization`, {organizationId});
}
// auth functions

Client.prototype.login = function(payload){
  return this.pagedRequest('POST', '/auth/local', payload);
};

Client.prototype.currentUser = function(){
  return this.pagedGetRequest('/users/me');
};

Client.prototype.loginWithGoogle = function(payload){
  return this.pagedGetRequest('/auth/google', true)
};

// Organization functions

Client.prototype.getOrganizations = function(){
  return this.pagedGetRequest('/organizations');
};

Client.prototype.getOrganization = function(id){
  return this.pagedGetRequest(`/organizations/${id}`);
};

Client.prototype.createOrganization = function(organizationInfo){
  return this.pagedRequest('POST', `/organizations`, organizationInfo);
};

Client.prototype.updateOrganization = function(organizationInfo){
  return this.pagedRequest('PUT', `/organizations/${organizationInfo.id}`, organizationInfo);
};

Client.prototype.deleteOrganization = function(id){
  return this.pagedRequest('DELETE', `/organizations/${id}`);
};

Client.prototype.deleteUser = function(){
  return this.pagedRequest('DELETE', `/users/me/delete`);
}

Client.prototype.getOrganizationsForUser = function(userId){
  return this.pagedGetRequest(`/users/${userId}/organizations`);
};

Client.prototype.getOrganizaionsAndItsUsers = function(userId){
  return this.pagedGetRequest(`/users/${userId}/organizations-and-users`);
}

Client.prototype.getInvoicesForOrganization = function(orgId){
  return this.pagedGetRequest(`/organizations/${orgId}/invoices`)
};

Client.prototype.getUsageInformationForOrganization = function(orgId){
  return this.pagedGetRequest(`/organizations/${orgId}/usage`)
};

Client.prototype.getCountOfRequestsFromCurrentPeriodForOrganization = function(orgId){
  return this.pagedGetRequest(`/organizations/${orgId}/requests`)
};

Client.prototype.getUsersForOrganization = function(orgId){
  return this.pagedGetRequest(`/organizations/${orgId}/users`)
}

Client.prototype.inviteUserToOrganization = function(orgId, email, role){
  return this.pagedRequest('POST', `/organizations/${orgId}/users`, {email, role})
}

Client.prototype.removeUserFromOrganization = function(orgId, removedUserId){
  return this.pagedRequest('DELETE', `/organizations/${orgId}/users`, { removedUserId } )
}

Client.prototype.getOrganizationInvitesForUser = function(userId){
  return this.pagedGetRequest(`/users/${userId}/organization-invites`)
}

Client.prototype.updateOrganizationInvite = function(invitationId, status){
  return this.pagedRequest('PUT', `/users/${invitationId}/update-organization-invite`, {invitationId, status})
}

Client.prototype.updateUserRoleInOrganization = function(orgId, userId, role){
  return this.pagedRequest('PUT', `/organizations/${orgId}/update-user-role`, {userId, role})
}

Client.prototype.getOrganizationTier = function(orgId){
  return this.pagedGetRequest(`/organizations/${orgId}/current-cycle-information`)
}

// Plan functions

Client.prototype.getPlans = function(){
  return this.pagedGetRequest('/plans');
};

Client.prototype.getPlan = function(id){
  return this.pagedGetRequest(`/plans/${id}`);
};

Client.prototype.updatePlan = function(planInfo){
  return this.pagedRequest('PUT', `/plans/${planInfo.id}`, planInfo);
};

Client.prototype.deletePlan = function(id){
  return this.pagedRequest('DELETE', `/plans/${id}`);
};

Client.prototype.getPlansForOrganization = function(organizationId){
  return this.pagedGetRequest(`/organizations/${organizationId}/plans`);
};

Client.prototype.createPlanForOrganization = function(organizationId, planInfo){
  return this.pagedRequest('POST', `/organizations/${organizationId}/plans`, planInfo);
};

Client.prototype.getPlanByOrganizationAndApi = function(organizationId, apiId){
  return this.pagedGetRequest(`/organizations/${organizationId}/plans/${apiId}`);
};

// ApiLog functions

Client.prototype.getApiLogs = function(){
  return this.pagedGetRequest('/apilogs');
};

Client.prototype.getApiLog = function(id){
  return this.pagedGetRequest(`/apilogs/${id}`);
};

Client.prototype.updateApiLog = function(apiLogInfo){
  return this.pagedRequest('PUT', `/apilogs/${apiLogInfo.id}`, apiLogInfo);
};

Client.prototype.deleteApiLog = function(id){
  return this.pagedRequest('DELETE', `/apilogs/${id}`);
};

Client.prototype.getApiLogsForPlan = function(planId){
  return this.pagedGetRequest(`/plans/${planId}/apilogs`);
};

Client.prototype.createApiLogForPlan = function(planId, apiLogInfo){
  return this.pagedRequest('POST', `/plans/${planId}/apilogs`, apiLogInfo);
};

Client.prototype.trimApiLogs = function(){
  return this.pagedRequest('DELETE', '/util/apilogs/trim' )
};

// Api functions

Client.prototype.getApis = function(){
  return this.pagedGetRequest('/apis');
};

Client.prototype.getApiProxyConfigs = function(){
  return this.pagedGetRequest('/apis/proxyconfig');
};

Client.prototype.getApi = function(id){
  return this.pagedGetRequest(`/apis/${id}`);
};

Client.prototype.getApiByFriendlyName = function(friendlyName){
  return this.pagedGetRequest(`/apis/friendly/${friendlyName}`);
};

Client.prototype.createApi = function(apiInfo){
  return this.pagedRequest('POST', `/apis`, apiInfo);
};

Client.prototype.updateApi = function(apiInfo){
  return this.pagedRequest('PUT', `/apis/${apiInfo.id}`, apiInfo);
};

Client.prototype.deleteApi = function(id){
  return this.pagedRequest('DELETE', `/apis/${id}`);
};

Client.prototype.sendSupportEmail = function(apiId, userId, payload){
  return this.pagedRequest('POST', `/apis/${apiId}/support/${userId}`, payload);
};

// AutomatedMessage functions

Client.prototype.getAutomatedMessages = function(){
  return this.pagedGetRequest('/automatedmessages');
};

Client.prototype.getAutomatedMessage = function(id){
  return this.pagedGetRequest(`/automatedmessages/${id}`);
};

Client.prototype.createAutomatedMessage = function(automatedMessageInfo){
  return this.pagedRequest('POST', `/automatedmessages`, automatedMessageInfo);
};

Client.prototype.updateAutomatedMessage = function(automatedMessageInfo){
  return this.pagedRequest('PUT', `/automatedmessages/${automatedMessageInfo.id}`, automatedMessageInfo);
};

Client.prototype.deleteAutomatedMessage = function(id){
  return this.pagedRequest('DELETE', `/automatedmessages/${id}`);
};

// Tier functions

Client.prototype.getTiers = function(){
  return this.pagedGetRequest('/tiers');
};

Client.prototype.getTier = function(id){
  return this.pagedGetRequest(`/tiers/${id}`);
};

Client.prototype.createTier = function(tierInfo){
  return this.pagedRequest('POST', `/tiers`, tierInfo);
};

Client.prototype.updateTier = function(tierInfo){
  return this.pagedRequest('PUT', `/tiers/${tierInfo.id}`, tierInfo);
};

Client.prototype.deleteTier = function(id){
  return this.pagedRequest('DELETE', `/tiers/${id}`);
};

// ApiKey functions

Client.prototype.getApiKeys = function(){
  return this.pagedGetRequest('/apikeys');
};

Client.prototype.getApiKey = function(id){
  return this.pagedGetRequest(`/apikeys/${id}`);
};

Client.prototype.createApiKey = function(apiKeyInfo){
  return this.pagedRequest('POST', `/apikeys`, apiKeyInfo);
};

Client.prototype.updateApiKey = function(apiKeyInfo){
  return this.pagedRequest('PUT', `/apikeys/${apiKeyInfo.id}`, apiKeyInfo);
};

Client.prototype.deleteApiKey = function(id){
  return this.pagedRequest('DELETE', `/apikeys/${id}`);
};

Client.prototype.getApiKeysForPlan = function(planId){
  return this.pagedGetRequest(`/plans/${planId}/apikeys`);
};

Client.prototype.createApiKeyForPlan = function(payload){
  return this.pagedRequest('POST', `/plans/${payload.planId}/apikeys`, payload);
};

Client.prototype.validateApiKey = function(payload){
  return this.pagedRequest('POST', '/apikeys/validate', payload);
};

Client.prototype.incrementApiKeyCallCount = function(id, count, requestType){
  return this.pagedRequest('POST', `/apikeys/${id}/increment`, {count, requestType});
};

// PlanPeriod functions

Client.prototype.getPlanPeriods = function(){
  return this.pagedGetRequest('/planperiods');
};

Client.prototype.getPlanPeriod = function(id){
  return this.pagedGetRequest(`/planperiods/${id}`);
};

Client.prototype.updatePlanPeriod = function(planPeriodInfo){
  return this.pagedRequest('PUT', `/planperiods/${planPeriodInfo.id}`, planPeriodInfo);
};

Client.prototype.deletePlanPeriod = function(id){
  return this.pagedRequest('DELETE', `/planperiods/${id}`);
};

Client.prototype.getPlanPeriodsForPlan = function(planId){
  return this.pagedGetRequest(`/plans/${planId}/planperiods`);
};

Client.prototype.createPlanPeriodForPlan = function(planId, planPeriodInfo){
  return this.pagedRequest('POST', `/plans/${planId}/planperiods`, planPeriodInfo);
};

Client.prototype.simulateStripeInvoiceReceived = function(payload){
  return this.pagedRequest('POST', '/stripe/webhooks/invoicecreated', payload)
};

Client.prototype.getLatestStripeSubscriptionDetails = function(userStripeId){
  return this.pagedGetRequest(`/stripe/subscriptions/${userStripeId}/latest`);
}

Client.prototype.getStripeTrialSubscriptionDetails = function(stripeSubscriptionId){
  return this.pagedGetRequest(`/stripe/subscription/${stripeSubscriptionId}/trial-info`);
}

Client.prototype.createStripePaymentIntent = function(payload){
  return this.pagedRequest('POST',`/stripe/paymentIntents`, payload);
}

Client.prototype.getStripeInfoForOrganization = function(orgId){
  return this.pagedGetRequest(`/organizations/${orgId}/stripe`);
}

// Intercom Functions
Client.prototype.startIntercomConversation = function(payload){
  return this.pagedRequest('POST', '/intercom/start-conversation', payload);
}

// ApiCreditRequest functions
Client.prototype.getTotalApiCreditsUsedForOrganization = function(organizationId, startDate, endDate){
  return this.pagedGetRequest(`/organizations/${organizationId}/apiCredits?startDate=${startDate}&endDate=${endDate}`);
}

Client.prototype.setOrgDefaultDashboard = function(orgId, view){
  return this.pagedRequest('PUT', `/organizations/${orgId}/default-dashboard`, { defaultDashboard: view});
}











