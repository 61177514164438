import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import './CurrentBillingCycleStyles.scss';

const CurrentBillingCycle = (props) => {
  const {
    currentBillingCycle,
    totalApiCreditsUsed,
    totalApiCreditsAllowed,
    isDeveloper,
    api,
    currentTier
  } = props;

  const {startDate: billingCycleBeginning, endDate: billingCycleEnd} = currentBillingCycle

  const totalApiCreditsPercentage = `${(totalApiCreditsUsed / totalApiCreditsAllowed) * 100}%`

  const renderTotalApiCreditsUsedBar = () => {
    return (
      currentBillingCycle.loading ?
        (<div className="spinner-border text-success" role="status">
          <span className="sr-only">Loading...</span>
        </div> ) : (
            <div className="progress" style={{width: 'inherit'}}>
              <div className="progress-bar bg-success progress-bar-striped progress-bar-animated" role="progressbar" style={{width: totalApiCreditsPercentage}} aria-valuenow="166"
                   aria-valuemin="0" aria-valuemax={totalApiCreditsAllowed}></div>
              </div>
        )
    )
  }

  const isDefaultTier = currentTier === api.defaultTierId

  return (
    <Col md={12} xl={6} sm={12}>
      <Card>
        <CardBody>
          {currentBillingCycle.loading ? (
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <>
              <div className="card__title">
                <h5 className="subhead">Current Billing Cycle</h5>
              </div>
              <form className="form form--vertical payment--form">
                <div className="form__form-group">
                  <span className="form__form-group-label">Period Beginning</span>
                  <div className="form__form-group-field">
                    <input
                      name="defaultInput"
                      type="text"
                      placeholder={moment(billingCycleBeginning).format('MMM Do YYYY')}
                      disabled
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Period Ending</span>
                  <div className="form__form-group-field">
                    <input
                      name="disableInput"
                      type="text"
                      placeholder={moment(billingCycleEnd).format('MMM Do YYYY')}
                      disabled
                    />
                  </div>
                </div>
                { isDeveloper && <div className="form__form-group">
                  <span className="form__form-group-label">Requests Made This Cycle:</span>
                  {totalApiCreditsAllowed && totalApiCreditsUsed && <span
                    className="form__form-group-field">{totalApiCreditsUsed.toLocaleString()} of {totalApiCreditsAllowed.toLocaleString()}</span>}
                  <div className="form__form-group-field">
                    {renderTotalApiCreditsUsedBar()}
                  </div>
                </div>}
              </form>
            </>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default CurrentBillingCycle;
