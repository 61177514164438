import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Input } from 'reactstrap';
import { connect } from 'react-redux';


const renderField = ({
                       input, placeholder, type, meta: { touched, error },
                     }) => (
  <div className="form__form-group-input-wrap">
    <Input {...input} placeholder={placeholder} type={type} />
  </div>
);

class CancelForm extends PureComponent {
  constructor() {
    super();
    this.state = {
      reason: 'Other', // Default reason
    };
  }

  render() {
    const { handleSubmit } = this.props;

    // We'll use this simple form for downgrades to free tier
    // The form is structured to match our simplified feedback format
    return (
      <form className="form">
        <div className="form__form-group">
          <span className="form__form-group-label">Reason for downgrading</span>
          <div className="form__form-group-field">
            <Field
              name="reason"
              component="input"
              type="hidden"
              value="Downgrade to Free Tier"
            />
            <Field
              name="explanation"
              component={renderField}
              type="textarea"
              placeholder="Please tell us why you're downgrading to the free tier"
            />
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state.form.cancel_form
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: 'cancel_form',
  initialValues: { reason: 'Downgrade to Free Tier' }
})(CancelForm));
