import {
  HANDLE_QUALITY_CHANGE,
  HANDLE_URL_CHANGE,
  HANDLE_VIEWPORT_WIDTH_CHANGE,
  MAKE_DEBUGGER_API_CALL,
  MAKE_DEBUGGER_API_CALL_ERROR,
  MAKE_DEBUGGER_API_CALL_SUCCESS, SET_API_RESPONSE_TAB,
  SET_API_TAB_VALUE,
  SET_DEBUGGER_FORM_ALERT,
  SET_DEBUGGER_RATE_LIMIT_ALERT,
  SET_FORCE_CACHE_UPDATE,
  SET_LANGUAGE_VALUE,
  SET_LAST_CALLED_URL,
  SET_PROXY,
  SET_SELECTED_ELEMENTS,
  TOGGLE_FULL_PAGE,
  TOGGLE_FULL_RENDER,
  BUILD_SNIPPET,
  SET_AUTO_PROXY,
  SET_HAS_AUTO_PROXY,
  SCROLL_TO_RESULTS,
    TOGGLE_EMBED_ORIENTATION,
    TOGGLE_ALWAYS_OG_FRAME,
    SET_RATE_LIMIT_SOURCE
} from '../actionTypes';
// import ReactGA from 'react-ga';
import {opengraphApiBase} from '../../constants/config';
import { TrackGoogleAnalyticsEvent } from '../../shared/utils/google-analytics';
import {createSegmentEvent} from '../segment';

export function setDebuggerFormAlert(payload){
  return dispatch => {
    dispatch({type: SET_DEBUGGER_FORM_ALERT, payload})
  }
}

export function setDebuggerRateLimitAlert(payload){
  return dispatch => {
    dispatch({type: SET_DEBUGGER_RATE_LIMIT_ALERT, payload})
  }
}

export function setRateLimitSource(payload){
  return dispatch => {
    dispatch({type: SET_RATE_LIMIT_SOURCE, payload})
  }
}

export function handleUrlChange(payload){
  console.log('handleUrlChange', payload)

  let newUrl = payload;
  if(newUrl && newUrl.length > 4 && newUrl.substring(0, 4) !== 'http') {
    newUrl = 'http://' + newUrl;
  }
  return dispatch => {
    dispatch({type: HANDLE_URL_CHANGE, newUrl})
  }
}

export function setApiTabValue(payload){
  return dispatch => {
    dispatch({type: SET_API_TAB_VALUE, payload})
  }
}

export function setApiResponseTabValue(value){

  return (dispatch, getState) => {
    const { apiTabValue } = getState().debuggerForm;

    const payload = { apiTabValue, value}
    dispatch({type: SET_API_RESPONSE_TAB, payload})
  }
}

export function setLanguageValue(payload){
  return dispatch => {
    dispatch({type: SET_LANGUAGE_VALUE, payload})
  }
}

export function toggleProxy(payload){
  return dispatch => {
    dispatch({type: SET_PROXY, payload})
  }
}

export function toggleForceCacheUpdate(){
  return dispatch => {
    dispatch({type: SET_FORCE_CACHE_UPDATE})
  }
}

export function toggleAutoProxy(){
  return dispatch => {
    dispatch({type: SET_AUTO_PROXY})
  }
}

export function toggleHasAutoProxy(payload){
  return dispatch => {
    dispatch({type: SET_HAS_AUTO_PROXY, payload})
  }
}

export function toggleFullRender(){
  return dispatch => {
    dispatch({type: TOGGLE_FULL_RENDER})
  }
}

export function setViewportWidth(payload){
  return dispatch => {
    dispatch({type: HANDLE_VIEWPORT_WIDTH_CHANGE, payload})
  }
}

export function setImageQuality(payload){
  return dispatch => {
    dispatch({type: HANDLE_QUALITY_CHANGE, payload})
  }
}

export function toggleFullPage(){
  return dispatch => {
    dispatch({type: TOGGLE_FULL_PAGE})
  }
}

export function setEmbedOrientation(){
  return dispatch => {
    dispatch({type: TOGGLE_EMBED_ORIENTATION})
  }
}

export function setAlwaysOgFrame(){
  return dispatch => {
    dispatch({type: TOGGLE_ALWAYS_OG_FRAME})
  }
}

export function handleHtmlElementChange(event) {
  return (dispatch, getState) => {

    const { name: selectedHtmlElement } = event.target;
    const { selectedHtmlElements } = getState().debuggerForm.extract.options;

    if(selectedHtmlElements.includes(selectedHtmlElement)) {
      const removedElementList = selectedHtmlElements.filter((element) => element !== selectedHtmlElement)
      dispatch({type: SET_SELECTED_ELEMENTS, payload: removedElementList})
    } else {
      dispatch({type: SET_SELECTED_ELEMENTS, payload: [...selectedHtmlElements, selectedHtmlElement] })
    }
  }
}

async function readFirstCharactersFromStream(stream, characterCount) {
  const decoder = new TextDecoder();
  let reader = stream.getReader();
  let result = '';
  let remainingCharacters = characterCount;

  while (remainingCharacters > 0) {
    const { value, done } = await reader.read();
    if (done) {
      // End of stream reached
      break;
    }

    const chunk = decoder.decode(value, { stream: true });
    const chunkLength = Math.min(chunk.length, remainingCharacters);

    result += chunk.slice(0, chunkLength);
    remainingCharacters -= chunkLength;

    if (remainingCharacters <= 0) {
      // Stop reading once desired character count is reached
      break;
    }
  }

  // Close the reader
  await reader.cancel();

  return result + '\n Truncated Response ... ';
}


export function makeDebuggerApiCall(e){

    return (dispatch, getState) => {
      e.preventDefault();
      const userId = getState().users.user.id;
      let meta =  {
        analytics: createSegmentEvent('identify', {
          userId,
          traits: {
            debugToolUsed: 'yes',
            debugToolLastUsed: new Date().toISOString()
          }
        })
      };

      const url = getState().debuggerForm.defaultOptions.url;

      const apiTabValue = getState().debuggerForm.apiTabValue;
      const fetchUrl = dispatch(buildDebuggerApiCallURL(apiTabValue))
      const lastCalledUrlPayload = { fetchUrl, apiTabValue };

      dispatch({type: SET_LAST_CALLED_URL, lastCalledUrlPayload })
      dispatch({type: MAKE_DEBUGGER_API_CALL, meta})
      TrackGoogleAnalyticsEvent('debugToolCall','Usage Metrics', 'debugToolCall', url)

      fetch(fetchUrl)
        .then((response) => {
          if(apiTabValue === 'scrape'){
            if(parseInt(response.headers.get('Content-Length')) > 100000) {
              console.log('Truncating response..')
              return readFirstCharactersFromStream(response.body, 100000)
            }
            return response.text()
          }
          return response.json()
        })
        .then((results) => {
          // Check if this is a rate limit error
          if (results && results.error && results.error.code === 102 && results.error.message === 'Rate limit exceeded') {
            dispatch(setRateLimitSource('api'));
            dispatch(setDebuggerRateLimitAlert(true));
          }
          
          dispatch({ type: MAKE_DEBUGGER_API_CALL_SUCCESS, payload: { results, apiTabValue } })
          dispatch({ type: SCROLL_TO_RESULTS, payload: { scrollToResults: true } })
        })
        .then(() => {
          dispatch({ type: SCROLL_TO_RESULTS, payload: { scrollToResults: false } })
        })
        .catch((err) => {
          console.log('Error trying to make a debugger call', err);
          dispatch({ type: MAKE_DEBUGGER_API_CALL_ERROR, err })
        })
    }
}

function buildDebuggerApiCallURL(apiTabValue) {
  return (dispatch, getState) => {
    const defaultOptions = getState().debuggerForm.defaultOptions;
    const otherOptions = getState().debuggerForm[apiTabValue].options;

    const options = {...defaultOptions, ...otherOptions};


    const apiKey = getState().plans.detailPlan.apiKeys[0].key;
    // let baseURL = process.env.NODE_ENV === 'production' ? 'https://opengraph.io/api/1.1/' : 'http://localhost:3778/opengraph/api/1.1/';
    let url = `${opengraphApiBase}${apiTabValue}/${encodeURIComponent(options.url)}?accept_lang=${options.language.value}`;
    if(options.forceCacheUpdate){
      url = url + '&cache_ok=false';
    }

    if(apiTabValue !== 'screenshot' && options.hasAutoProxy) {
      url = url + `&auto_proxy=${options.autoProxy}`;
    }

    if(options.fullRender.active && apiTabValue !== 'screenshot'){
      url = url + '&full_render=true';
    }

    if(options.useProxy && apiTabValue !== 'screenshot'){
      url = url + '&use_proxy=true';
    }

    if(options.proxyChoice && options.proxyChoice !== 'noProxy' && apiTabValue !== 'screenshot'){
      url = url + `&${options.proxyChoice}=true`;
    }

    if(options.selectedHtmlElements && apiTabValue === 'extract' ){
      const urlHtmlElements = options.selectedHtmlElements.join(',')
      url = url + `&html_elements=${urlHtmlElements}`;
    }

    if(options.imageQuality && apiTabValue === 'screenshot'){
      url = url + `&quality=${options.imageQuality}`;
    }

    if(options.viewportWidth && apiTabValue === 'screenshot'){
      url = url + `&dimensions=${options.viewportWidth}`;
    }

    if(options.fullPage && apiTabValue === 'screenshot'){
      url = url + `&full_page=${options.fullPage}`;
    }

    if(options.orientation && apiTabValue === 'oembed'){
      url = url + `&orientation=${options.orientation}`;
    }

    if(options.alwaysOgFrame && apiTabValue === 'oembed'){
      url = url + `&always_og_frame=${options.alwaysOgFrame}`;
    }

    return url + `&app_id=${apiKey}`;
  }
}

export function calculateApiCreditCost(){

  return (dispatch, getState) => {
    const {fullRender, proxyChoice} = getState().debuggerForm.defaultOptions;

    let cost = 0;

    if(!fullRender.active && proxyChoice === 'noProxy'){
      return 1
    }

    if(fullRender.active){
      cost += fullRender.cost;
    }
    if(proxyChoice && proxyChoice !== 'noProxy'){
      cost += getState().debuggerForm.defaultOptions.proxyOptions.find((option) => option.value === proxyChoice).cost;
    }
    return cost;
  }
}

export const snippetLanguages = {
  node_axios: {
    name: 'Node',
    value: 'node_axios',
    image: '/js_logo.svg',
    language: 'javascript'
  },
  curl: {
    name: 'Curl',
    value: 'curl',
    language: 'bash',
    image: '/bash_logo.png'
  },
  python_requests: {
    name: 'Python',
    value: 'python_requests',
    language: 'python',
    image: '/python_logo.svg'
  },
  php_curl: {
    name: 'PHP',
    value: 'php_curl',
    language: 'php',
    image: '/php_logo.svg'
  },
  go: {
    name: 'Go',
    value: 'go',
    language: 'go',
    image: '/golang_logo.png'
  },
  java: {
    name: 'Java',
    value: 'java',
    language: 'java',
    image: '/java_logo.svg'
  },
  ruby: {
    name: 'Ruby',
    value: 'ruby',
    language: 'ruby',
    image: '/ruby_logo.svg'
  }
}
export const buildSnippet = (lang) => {
  console.log('in build snippet')
  return (dispatch, getState) => {
    const apiTabValue = getState().debuggerForm.apiTabValue;
    const plan = getState().plans.detailPlan;
    const apiKeys = plan.apiKeys || [];
    const key = apiKeys[0] || {};
    const token = key.key;
    if(!token){
      console.log('No token found SNIPPET BUILDER')
      return null;
    }
    const url = dispatch(buildDebuggerApiCallURL(apiTabValue))
    let snippet, language;
    switch (lang) {
      case 'node_axios':
        snippet = `
const axios = require('axios');

let config = {
  method: 'get',
  maxBodyLength: Infinity,
  url: '${url}'
};

axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  });
      `;
        language = 'javascript'
          console.log('building snippet', {snippet, language})
        return dispatch({type: BUILD_SNIPPET, payload: {snippet, language}})
      case 'curl':
        snippet = `
curl --location --request GET '${url}'`;
        language = 'bash';
        return dispatch({type: BUILD_SNIPPET, payload: {snippet, language}})
      case 'python_requests':
        snippet = `
import requests

url = "${url}"
headers = {
}

response = requests.request("GET", url, headers=headers)

print(response.text)
        `;
        language = 'python';
        return dispatch({type: BUILD_SNIPPET, payload: {snippet, language}})
      case 'php_curl':
        snippet = `
<?php
$curl = curl_init();

curl_setopt_array($curl, array(
CURLOPT_URL => '${url}',
CURLOPT_RETURNTRANSFER => true,
CURLOPT_ENCODING => '',
CURLOPT_MAXREDIRS => 10,
CURLOPT_TIMEOUT => 0,
CURLOPT_FOLLOWLOCATION => true,
CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
CURLOPT_CUSTOMREQUEST => 'GET',
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;
        `
        language = 'php';
        return dispatch({type: BUILD_SNIPPET, payload: {snippet, language}})
      case 'go':
        snippet = `
package main

import (
  "fmt"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "${url}"
  method := "GET"

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, nil)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("admin-token", "${token}")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}
        `;
         language = 'go';
         return dispatch({type: BUILD_SNIPPET, payload: {snippet, language}})
      case 'java':
        snippet = `
import java.net.HttpURLConnection;
import java.net.URL;
import java.io.BufferedReader;
import java.io.InputStreamReader;
import java.util.stream.Collectors;

public class Main {
  public static void main(String[] args) {
    try {
      URL url = new URL("${url}");
      HttpURLConnection conn = (HttpURLConnection) url.openConnection();
      conn.setRequestMethod("GET");
      conn.setDoOutput(true);
      conn.setDoInput(true);

      BufferedReader reader = new BufferedReader(new InputStreamReader(conn.getInputStream()));
      String response = reader.lines().collect(Collectors.joining());
      System.out.println(response);
      reader.close();
    } catch (Exception e) {
      e.printStackTrace();
    }
  }
}
      `;
        language = 'java';
        return dispatch({type: BUILD_SNIPPET, payload: {snippet, language}})
      case 'ruby':
        snippet = `
require "uri"
require "net/http"

url = URI("${url}")

https = Net::HTTP.new(url.host, url.port)
https.use_ssl = true

request = Net::HTTP::Get.new(url)

response = https.request(request)
puts response.read_body
      `;
        language = 'ruby';
        return dispatch({type: BUILD_SNIPPET, payload: {snippet, language}})
    }
  }
}
