import React from 'react';
import { Col, Row, TabPane, TabContent } from 'reactstrap';
import YourApiKey from '../YourApiKey/YourApiKey';
import ApiCreditsUsed from '../ApiCreditsUsed/ApiCreditsUsed';
import DayByDayUsageChart from '../DayByDayUsageChart';
import ApiKeysTable from '../ApiKeysTable';
import ApiLogsTable from '../ApiLogsTable';
import DebugToolWidget from '../../../../components/DebugToolWidget/DebugToolWidget';
import DebugToolWidgetResults from '../../../../components/DebugToolWidget/DebugToolWidgetResults';
import RateLimitUpgradeModal from '../../../../shared/components/Modals/RateLimitUpgradeModal';

const ApiDetailsTabContent = ({ activeTab, planApiKey, currentOrg, toggleTabChange }) => {

  return (
    <>
      <RateLimitUpgradeModal />
      <TabContent activeTab={activeTab}>
        <TabPane tabId="dashboard">
          <Row>
            <Col md={6} >
              <YourApiKey toggleTabChange={toggleTabChange} planApiKey={planApiKey} />
            </Col>
            <Col md={6} >
              <ApiCreditsUsed currentCycleInformation={currentOrg} />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <DebugToolWidget />
              <DebugToolWidgetResults />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="api-keys">
          <Row>
            <Col md={12}>
              <ApiKeysTable />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="api-logs">
          <Row>
            <Col md={12}>
              <ApiLogsTable />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="api-usage">
          <Row>
            <Col md={12}>
              <DayByDayUsageChart />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </>
  )
}

export default ApiDetailsTabContent;
