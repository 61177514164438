import {
  EDIT_LINK_PREVIEW_META_DATA,
  EDIT_UPDATED_RESULTS,
  MAKE_LINK_PREVIEW_CALL,
  MAKE_LINK_PREVIEW_CALL_ERROR,
  MAKE_LINK_PREVIEW_CALL_SUCCESS,
  SET_ACTIVE_LINK_PREVIEW_TAB,
  TOGGLE_MARKETING_SIGN_UP_MODAL,
  SET_SEO_AI_META_TAGS,
  TOGGLE_AI_MODAL,
  GENERATE_SEO_TAGS,
  GENERATE_SEO_TAGS_SUCCESS,
  GENERATE_SEO_TAGS_ERROR,
  TOGGLE_MISSING_TAGS_ALERT,
  GET_IMAGE_LAYOUTS,
  GET_IMAGE_LAYOUTS_SUCCESS,
  GET_IMAGE_LAYOUTS_FAIL,
  TOGGLE_IMAGE_LAYOUT_MODAL,
  SET_MODAL_SELECTED_LAYOUT,
  SET_MODAL_STEP,
  GET_ORGANIZATION_IMAGE_DESIGNS,
  GET_ORGANIZATION_IMAGE_DESIGNS_SUCCESS,
  GET_ORGANIZATION_IMAGE_DESIGNS_FAIL,
  SET_SELECTED_DESIGN,
  SET_SELECTED_DESIGN_TAB_SWITCH,
  SET_MODAL_IS_DESIGN_MODE,
  CREATE_DESIGN,
  CREATE_DESIGN_SUCCESS,
  CREATE_DESIGN_ERROR,
  UPDATE_MODAL_FORM_FIELD,
  RESET_MODAL_FORM_FIELDS,
  CLEAR_DESIGN_ERROR,
  SET_SELECTED_DESIGN_LOADING
} from '../actionTypes';

import { opengraphApiBase, imageServiceApi, imageServiceThumbnail, imageServiceLayouts } from '../../constants/config';
import { TrackGoogleAnalyticsEvent } from '../../shared/utils/google-analytics';
import { setDebuggerRateLimitAlert, setRateLimitSource } from './debuggerForm';

export const handleEditMetaData = (e) => {
  const { name, value } = e.target;

  return (dispatch, getState) => {
    dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name, value });
    const results = getState().linkPreview.results;

    if(results && results.hybridGraph && name !== 'image') {
      dispatch({ type: EDIT_UPDATED_RESULTS, payload: { ...results, hybridGraph: {  ...results.hybridGraph, [name]: value } , openGraph: {  ...results.openGraph, [name]: value }, htmlInferred: {  ...results.htmlInferred, [name]: value } } });
    }

    if(name === 'image') {
      dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'image', value });
      dispatch({ type: EDIT_UPDATED_RESULTS, payload: { ...results, hybridGraph: {  ...results.hybridGraph, image: value , imageSecureUrl: value }, openGraph: {  ...results.openGraph, image: { url: value, secure_url: value } }, htmlInferred: {  ...results.htmlInferred, image: { url: value } } } });
    }
  }
};

export const handleEditMetaDataFromResults = (name, value) => {
  return (dispatch, getState) => {
    dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name, value });
    const results = getState().linkPreview.results;

    if(results && results.hybridGraph) {
      dispatch({ type: EDIT_UPDATED_RESULTS, payload: { ...results, hybridGraph: {  ...results.hybridGraph, [name]: value } , openGraph: {  ...results.openGraph, [name]: value }, htmlInferred: {  ...results.htmlInferred, [name]: value } } });
    }

    if(name === 'image') {
      dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'image', value });
      dispatch({ type: EDIT_UPDATED_RESULTS, payload: { ...results, hybridGraph: {  ...results.hybridGraph, image: { url: value }, imageSecureUrl: value }, openGraph: {  ...results.openGraph, image: { url: value } }, htmlInferred: {  ...results.htmlInferred, image: { url: value } } } });
    }
  }
}

export const setLinkPreviewUrl = (url) => {
  return (dispatch) => {
    dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'url', value: url });
  }
}

export const setActiveTab = (tab) => {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVE_LINK_PREVIEW_TAB, tab });
  }
}

export const toggleMarketingSignUpModal = (open) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_MARKETING_SIGN_UP_MODAL, open });
  }
}

export const generateAiMetaData = () => {
  return (dispatch, getState) => {
    const previewUrl = getState().linkPreview.form.url;
    const apiKey = getState().plans.detailPlan.apiKeys[0].key;
    let url = `${opengraphApiBase}site/ai/seo/${encodeURIComponent(previewUrl)}?app_id=${apiKey}&cache_ok=false`;

    dispatch({type: GENERATE_SEO_TAGS})

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if(results.error) {
          if(results.error.code === 102) {
            dispatch(setRateLimitSource('linkPreview'));
            dispatch(setDebuggerRateLimitAlert(true));
            dispatch({ type: GENERATE_SEO_TAGS_ERROR, payload: results.error })
          }
          dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: results.error.message })
          dispatch({ type: GENERATE_SEO_TAGS_ERROR, payload: results.error.message })
        } else {
          // Track AI optimization event
          TrackGoogleAnalyticsEvent('aiOptimization', 'Link Preview', 'aiOptimization', previewUrl);
          
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'title', value: results.response.title  });
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'description', value: results.response.description });
          dispatch({ type: GENERATE_SEO_TAGS_SUCCESS, payload: { title: results.response.title, description: results.response.description } } );
        }
      })
      .catch((err) => {
        console.log('Error trying to preview a link.', err);
      })
  }
}

export const constSetSeoAiMetaData = (results) => {
  return (dispatch) => {
    dispatch({ type: SET_SEO_AI_META_TAGS, results });
  }
}

export function makeLinkPreviewCall(e){
  e.preventDefault()
  return (dispatch, getState) => {

    const previewUrl = getState().linkPreview.form.url;
    const apiKey = getState().plans.detailPlan.apiKeys[0].key;
    // let baseURL = process.env.NODE_ENV === 'production' ? 'https://opengraph.io/api/1.1/' : 'http://localhost:3778/opengraph/api/1.1/';
    let url = `${opengraphApiBase}link-preview/${encodeURIComponent(previewUrl)}?app_id=${apiKey}&cache_ok=false`;

    dispatch({type: MAKE_LINK_PREVIEW_CALL})
    //TODO: Create google Analytics Event
    // TrackGoogleAnalyticsEvent('debugToolCall','Usage Metrics', 'debugToolCall', url)

    fetch(url)
      .then((response) => {
        if(!response.ok) {
          if(response.status === 504) {
            dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: 'OpenGraph is taking longer than usual to process this link. Please try again.' })
            throw new Error('OpenGraph is taking longer than usual to process this link. Please try again.')
          }
          if(response.status === 403) {
            dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: 'You have reached the limit of your plan. Please upgrade your plan to continue.' });
            dispatch(setRateLimitSource('linkPreview'));
            dispatch(setDebuggerRateLimitAlert(true));
            throw new Error('You have reached the limit of your plan. Please upgrade your plan to continue.');
          }
          dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: 'There was an error previewing this link. Please try again.' })
          throw new Error('There was an error previewing this link. Please try again.')
        }
        return response.json();
      })
      .then((results) => {
        if(results.error) {
          if(results.error.code === 102) {
            dispatch(setRateLimitSource('linkPreview'));
            dispatch(setDebuggerRateLimitAlert(true));
            dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: results.error.message });
            return;
          }
          dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: results.error.message })
        }
        if(results.code && results.code === -1012) {
          dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: results.message })
          return
        }
        console.log('HERE ARE THE RESULTS', results.hybridGraph);

        dispatch({ type: MAKE_LINK_PREVIEW_CALL_SUCCESS, results });

        const { image, title, description, url, type } = results.hybridGraph || {};

        if( !results.openGraph.title || !results.openGraph.description) {
          dispatch({ type: TOGGLE_MISSING_TAGS_ALERT, open: true})
        }

        if (image) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'image', value: image });
        }
        if (title) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'title', value: title });
        }
        if (description) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'description', value: description });
        }
        if(url) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'url', value: url });
        }
        if(type) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'type', value: type });
        }
      })
      .catch((err) => {
        console.log('Error trying to preview a link.', err);
        dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: err.message })
      })
  }
}

export const dismissMissingTagsAlert = () => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_MISSING_TAGS_ALERT, open: false })
  }
}

export const dismissErrorAlert = () => {
  return (dispatch) => {
    dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: null })
  }
}

export const getImageLayouts = () => {
  return (dispatch, getState) => {
    // let baseURL = process.env.NODE_ENV === 'production' ? 'https://opengraph.io/api/1.1/' : 'http://localhost:3778/opengraph/api/1.1/';
    const apiKey = getState().plans.detailPlan.apiKeys[0].key;
    let url = `${imageServiceLayouts}?app_id=${apiKey}`;

    dispatch({ type: GET_IMAGE_LAYOUTS });

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        for (const layout of results) {
          layout.imageUrl = `/img/${layout.id}.webp`;
        }
        dispatch({ type: GET_IMAGE_LAYOUTS_SUCCESS, results });
      })
      .catch((err) => {
        dispatch({ type: GET_IMAGE_LAYOUTS_FAIL });
        console.log('Error trying to get image layouts.', err);
      })
  }
}

export const getOrganizationImageDesigns = () => {
  return (dispatch, getState) => {
    let currentOrganization = getState().organization.currentOrg.information;
    let organizationId = currentOrganization.id;
    const apiKey = getState().plans.detailPlan.apiKeys[0].key;

    let url = `${imageServiceApi}/organization/${organizationId}/designs?app_id=${apiKey}`;
    dispatch({ type: GET_ORGANIZATION_IMAGE_DESIGNS });

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        dispatch({ type: GET_ORGANIZATION_IMAGE_DESIGNS_SUCCESS, results });
      })
      .catch((err) => {
        dispatch({ type: GET_ORGANIZATION_IMAGE_DESIGNS_FAIL, err });
        console.log('Error trying to get image layouts.', err);
      })
  }
}

export const createDesign = (templateFields, linkPreviewTool) => {
  return (dispatch, getState) => {
    let user = getState().users.user;
    let organizationId = user.defaultOrgId;
    const apiKey = getState().plans.detailPlan.apiKeys[0].key;

    // If templateFields is provided directly, use it, otherwise get from Redux state
    const formFields = templateFields || getState().linkPreview.imageLayoutModal.formFields;
    const { isDesignMode } = getState().linkPreview.imageLayoutModal;
    
    const layoutId = getState().linkPreview.imageLayoutModal.selectedLayout.id;
    const { title, subtitle: siteText, imageUrl: image, ctaText, name } = formFields;
    
    const encodedImageUrl = encodeURIComponent(image);
    let url = `${imageServiceApi}/organization/${organizationId}/design?app_id=${apiKey}`;

    dispatch({ type: CREATE_DESIGN });
    
    // Reset form fields right after dispatching CREATE_DESIGN
    dispatch({ type: RESET_MODAL_FORM_FIELDS });

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        layoutId: layoutId, 
        params: { 
          imageUrl: image, 
          title, 
          siteText, 
          ctaText 
        },
        ...(isDesignMode ? { name } : {})
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to create design: ${response.status} ${response.statusText}`);
        }
        return response.json();
      })
      .then((results) => {
        const { design, designParameter } = results;
        design.DesignParameters = [];
        design.DesignParameters.push(designParameter);

        dispatch({ type: CREATE_DESIGN_SUCCESS, results: design });
        dispatch({ type: TOGGLE_IMAGE_LAYOUT_MODAL, open: false });
        
        // Track design creation event with layout ID
        TrackGoogleAnalyticsEvent('designCreated', 'Design Tool', 'designCreated', `${layoutId}`);
        
        // Create thumbnail URL and update the LinkPreviewTool image field
        const thumbnailUrl = designParameter.cdnUrl;
        dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'image', value: thumbnailUrl });
        
        // Also update the results if we're using the LinkPreviewTool
        const linkPreviewResults = getState().linkPreview.results;
        if (linkPreviewResults && linkPreviewResults.hybridGraph) {
          dispatch({ 
            type: EDIT_UPDATED_RESULTS, 
            payload: { 
              ...linkPreviewResults, 
              hybridGraph: { ...linkPreviewResults.hybridGraph, image: thumbnailUrl, imageSecureUrl: thumbnailUrl }, 
              openGraph: { ...linkPreviewResults.openGraph, image: { url: thumbnailUrl, secure_url: thumbnailUrl } }, 
              htmlInferred: { ...linkPreviewResults.htmlInferred, image: { url: thumbnailUrl } } 
            } 
          });
        }
      })
      .catch((err) => {
        console.log('Error trying to create a design.', err);
        console.log('Error status:', err.status);
        console.log('Error message:', err.message);
        const errorMessage = err.message || 'Failed to create design. Please try again.';
        dispatch({ type: CREATE_DESIGN_ERROR, payload: errorMessage });
      })
  }
}

export const getDesignById = (designId, page = 1, limit = 10) => {
  return (dispatch, getState) => {
    let currentOrganization = getState().organization.currentOrg.information;
    let organizationId = currentOrganization.id;
    const apiKey = getState().plans.detailPlan.apiKeys[0].key;

    let url = `${imageServiceApi}/organization/${organizationId}/design/${designId}?app_id=${apiKey}&page=${page}&limit=${limit}`;
    
    dispatch({ type: SET_SELECTED_DESIGN_LOADING, loading: true });
    
    return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to fetch design: ${response.status} ${response.statusText}`);
        }
        return response.json();
      })
      .then((designResponse) => {
        // Design response now includes the design with paginated DesignParameters and pagination info
        const design = {
          ...designResponse,
          // Keep the design structure as expected by the application
          DesignParameters: designResponse.DesignParameters || [],
          pagination: designResponse.pagination || {
            total: 0,
            page: 1,
            limit: 10,
            totalPages: 0,
            hasNextPage: false,
            hasPreviousPage: false
          }
        };
        
        dispatch({ type: SET_SELECTED_DESIGN, design });
        dispatch({ type: SET_SELECTED_DESIGN_LOADING, loading: false });
        return design;
      })
      .catch((err) => {
        console.log('Error fetching design by ID:', err);
        dispatch({ type: SET_SELECTED_DESIGN_LOADING, loading: false });
        throw err;
      });
  };
};

export const loadMoreDesignParameters = (designId, page, limit = 10) => {
  return (dispatch, getState) => {
    return dispatch(getDesignById(designId, page, limit));
  };
};

export const createImage = (imageFields, designId) => {
  return (dispatch, getState) => {
    // If no designId is provided, don't make the call
    if (!designId) {
      console.log('No design ID provided for createImage. Skipping image creation.');
      const errorMessage = 'No design selected. Please select a design before rendering an image.';
      dispatch({ type: CREATE_DESIGN_ERROR, payload: errorMessage });
      return;
    }
    
    // Set loading state to true before starting the request
    dispatch({ type: CREATE_DESIGN });
    
    // If imageFields is provided directly, use it, otherwise get from Redux state
    const formFields = imageFields || getState().linkPreview.imageLayoutModal.formFields;
    const { title, subtitle: siteText, imageUrl: image, ctaText } = formFields;
    const encodedImageUrl = encodeURIComponent(image);
    const apiKey = getState().plans.detailPlan.apiKeys[0].key;
    
    const imageUrl = `${imageServiceThumbnail}/${designId}/${encodedImageUrl}/${title}/:${siteText}/${ctaText}/image.png?app_id=${apiKey}`;
    
    // Fetch the image
    fetch(imageUrl)
      .then((response) => {
        console.log('Response status:', response);
        if (!response.ok) {
          throw new Error(`Failed to fetch image: ${response.status} ${response.statusText}`);
        }
        return response.url;
      })
      .then((fetchedImageUrl) => {
        // Set loading to false and close the modal after setting the image
        dispatch({ type: CREATE_DESIGN_SUCCESS, results: { id: designId } });
        
        // Track image rendering event
        TrackGoogleAnalyticsEvent('imageRendered', 'Design Tool', 'imageRendered', designId);
        
        // Reset form fields
        dispatch({ type: RESET_MODAL_FORM_FIELDS });
        
        // Update the LinkPreviewTool image field with the generated image URL
        dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'image', value: fetchedImageUrl });
        
        // Also update the results if we're in the LinkPreviewTool
        const linkPreviewResults = getState().linkPreview.results;
        if (linkPreviewResults && linkPreviewResults.hybridGraph) {
          dispatch({ 
            type: EDIT_UPDATED_RESULTS, 
            payload: { 
              ...linkPreviewResults, 
              hybridGraph: { ...linkPreviewResults.hybridGraph, image: fetchedImageUrl, imageSecureUrl: fetchedImageUrl }, 
              openGraph: { ...linkPreviewResults.openGraph, image: { url: fetchedImageUrl, secure_url: fetchedImageUrl } }, 
              htmlInferred: { ...linkPreviewResults.htmlInferred, image: { url: fetchedImageUrl } } 
            } 
          });
        }
        
        // Get the specific design by ID with first page of parameters
        return dispatch(getDesignById(designId, 1, 10));
      })
      .catch((err) => {
        console.log('Error trying to fetch the image.', err);
        const errorMessage = err.message || 'Failed to generate image. Please check your parameters and try again.';
        dispatch({ type: CREATE_DESIGN_ERROR, payload: errorMessage });
      });
  }
}

export const toggleImageLayoutModal = (open) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_IMAGE_LAYOUT_MODAL, open });
    dispatch({ type: SET_MODAL_IS_DESIGN_MODE, isDesignMode: true });
    setTimeout(() => {
      dispatch({ type: SET_MODAL_STEP, step: 1 });
    }, 500);
  }
}

export const toggleSpecificImageLayoutModal = (open, layout) => {
  return (dispatch) => {
    dispatch({ type: SET_MODAL_STEP, step: 2 });
    dispatch({ type: SET_MODAL_IS_DESIGN_MODE, isDesignMode: false });
    dispatch({ type: SET_MODAL_SELECTED_LAYOUT, layout });
    dispatch({ type: TOGGLE_IMAGE_LAYOUT_MODAL, open });
  }
}

export const setModalStep = (step) => {
  return (dispatch) => {
    dispatch({ type: SET_MODAL_STEP, step });
  }
}

export const setModalSelectedLayout = (layout) => {
  return (dispatch) => {
    dispatch({ type: SET_MODAL_SELECTED_LAYOUT, layout });
  }
}

export const setModalIsDesignMode = (isDesignMode) => {
  return (dispatch) => {
    dispatch({ type: SET_MODAL_IS_DESIGN_MODE, isDesignMode });
  }
}

export const updateModalFormField = (fieldName, value) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_MODAL_FORM_FIELD, fieldName, value });
  }
}

export const resetModalFormFields = () => {
  return (dispatch) => {
    dispatch({ type: RESET_MODAL_FORM_FIELDS });
  }
}

export const clearDesignError = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_DESIGN_ERROR });
  }
}

export const setSelectedDesign = (design) => {
  return (dispatch, getState) => {
    if (design) {
      // Set loading state to true
      dispatch({ type: SET_SELECTED_DESIGN_LOADING, loading: true });
      
      // Immediately set the tab switch to true to ensure navigation
      dispatch({ type: SET_SELECTED_DESIGN_TAB_SWITCH, payload: true });
      
      // Set the basic design info immediately with a placeholder for DesignParameters
      const initialDesign = {
        ...design,
        DesignParameters: design.DesignParameters || []
      };
      dispatch({ type: SET_SELECTED_DESIGN, design: initialDesign });
      
      // Then fetch the complete design with parameters
      return dispatch(getDesignById(design.id, 1, 10))
        .catch(err => {
          console.error('Error loading design details:', err);
          dispatch({ type: SET_SELECTED_DESIGN_LOADING, loading: false });
        })
        .finally(() => {
          // Reset tab switch after a delay to ensure it worked
          setTimeout(() => {
            dispatch({ type: SET_SELECTED_DESIGN_TAB_SWITCH, payload: false });
          }, 300);
        });
    } else {
      dispatch({ type: SET_SELECTED_DESIGN, design: null });
      dispatch({ type: SET_SELECTED_DESIGN_TAB_SWITCH, payload: false });
    }
  }
}
