import React from 'react';
import { Col, Row, TabPane, TabContent } from 'reactstrap';
import LinkPreviewTool from '../LinkPreviewTool/LinkPreviewTool';

import OrganizationDesigns from '../OrganizationDesigns';
import OrganizationImages from '../OrganizationImages';


const TabContentComponent = ({ activeTab, design  }) => {

  return (
    <TabContent activeTab={activeTab}>
      <TabPane tabId="dashboard">
        <Row>
          <Col md={12}>
            <LinkPreviewTool />
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId={'designs'}>
        <Row>
          <Col md={12}>
            <OrganizationDesigns />
          </Col>
        </Row>
      </TabPane>
      { design ? <TabPane tabId={'images'}>
        <Row>
          <Col md={12}>
            <OrganizationImages />
          </Col>
        </Row>
      </TabPane> : null }
    </TabContent>
  )
}

export default TabContentComponent;
