import React, {Component} from 'react';
import OpengraphDataTable from './OpengraphDataTable';
import HybridDataTable from './HybridDataTable';
import HtmlInferredDataTable from './HtmlInferredDataTable';

export default class DebugResults extends Component {

  render(){
    return (
      <div>
        <OpengraphDataTable data={this.props.results.openGraph}/>
        <HybridDataTable data={this.props.results.hybridGraph}/>
        <HtmlInferredDataTable data={this.props.results.htmlInferred}/>
      </div>
    )
  }
}