import {
  SET_DASHBOARD_MODAL_ERROR,
  SET_DASHBOARD_VIEW,
  SWITCH_DASHBOARD_VIEW,
  TOGGLE_DASHBOARD_MODAL,
  SET_DEFAULT_DASHBOARD_VIEW,
  SET_DEFAULT_DASHBOARD_VIEW_SUCCESS,
  SET_DEFAULT_DASHBOARD_VIEW_ERROR
} from '../actionTypes';
import { apiClient } from '../../constants/clients';

const DASHBOARD_VIEW_STORAGE_KEY = 'dashboard_current_view';

// Get current dashboard view from localStorage
export function getCurrentDashboardViewFromStorage() {
  return localStorage.getItem(DASHBOARD_VIEW_STORAGE_KEY);
}

// Save current dashboard view to localStorage
export function saveCurrentDashboardViewToStorage(view) {
  localStorage.setItem(DASHBOARD_VIEW_STORAGE_KEY, view);
}

export function clearDashboardViewFromStorage() {
  localStorage.removeItem(DASHBOARD_VIEW_STORAGE_KEY);
}

export function setDashboardView(payload){
  return {type: SET_DASHBOARD_VIEW, payload};
}

export function toggleSwitchDashboardView(payload){
  return (dispatch) => {
    // Save to localStorage
    saveCurrentDashboardViewToStorage(payload);
    
    dispatch({ type: SET_DASHBOARD_VIEW, payload });
    dispatch({ type: SWITCH_DASHBOARD_VIEW, payload: true });

    setTimeout(() => {
      dispatch({ type: SWITCH_DASHBOARD_VIEW, payload: false });
    }, 50);
  }
}

export function toggleDashboardModal(){
  return {type: TOGGLE_DASHBOARD_MODAL}
}

export function setOrganizationalDashboardDefaultView(view){
  return (dispatch, getState) => {

    const orgId = getState().organization.currentOrg.information.id;
    if(!orgId) return;

    dispatch({ type: SET_DEFAULT_DASHBOARD_VIEW })
    apiClient.setOrgDefaultDashboard(orgId, view)
      .exec((err, result) => {
        if (err) {
          dispatch({ type: SET_DEFAULT_DASHBOARD_VIEW_ERROR, payload: err })
          dispatch({type: SET_DASHBOARD_MODAL_ERROR, payload: err});
        } else {
          // Save to localStorage
          saveCurrentDashboardViewToStorage(view);
          
          dispatch({ type: SET_DEFAULT_DASHBOARD_VIEW_SUCCESS });
          dispatch(toggleSwitchDashboardView(view));
          dispatch(toggleDashboardModal());
        }
      });
  }
}

export function updateDashboardView(view){
  return (dispatch, getState) => {
    const orgId = getState().organization.currentOrg.information.id;
    if(!orgId) return;
    
    dispatch({ type: SET_DASHBOARD_VIEW, payload: view });
    saveCurrentDashboardViewToStorage(view);
  }
}
