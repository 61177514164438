import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  Alert,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Tooltip,
  Badge,
  Form
} from 'reactstrap'
import DebugResults from './components/DebugResults';
import DebugApiResults from './components/DebugApiResults';
import {connect} from 'react-redux';
import {getLoggedInUser} from '../../redux/actions/users';
import {getPlanByApiAndOrganization} from '../../redux/actions/plans';
import {getApiByFriendlyName} from '../../redux/actions/apis';
import ReactPreview from './components/ReactPreview';
import ExtractForm from './components/Forms/ExtractForm';
import ScreenshotForm from './components/Forms/ScreenshotForm';
import ScreenshotPreview from './components/ScreenshotPreview';
import {
  handleHtmlElementChange, setApiResponseTabValue,
  setApiTabValue, setDebuggerFormAlert
} from '../../redux/actions/debuggerForm';
import BaseApiCallForm from './components/Forms/BaseApiCallForm';
import LoginModal from '../../shared/components/Modals/LoginModal';
import DebugRequestSnippet from './components/DebugRequestSnippet';
import {OembedResults} from './components/OembedResults';
import EmbedForm from './components/Forms/EmbedForm';
import RateLimitUpgradeModal from '../../shared/components/Modals/RateLimitUpgradeModal';

const DebugTool = (props) => {

  const { debuggerForm } = props;

  const [ screenshotTooltipOpen, setScreenshotTooltipOpen ] = useState(false);
  const [alertDismissed, setAlertDismissed] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [displayLoginModal, setDisplayLoginModal] = useState(true);
  const [ featuresAllowed, setFeaturesAllowed ] = useState(false);

  const apiPlayGroundResults = useRef(null);

  const scrollToResults = () => {
    if(apiPlayGroundResults.current){
      apiPlayGroundResults.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  useEffect(() => {
    if(debuggerForm.defaultOptions.scrollToResults) {
      scrollToResults()
    }
  }, [ debuggerForm.defaultOptions.scrollToResults ]);

  // useEffect(() => {
  //   props.getApiByFriendlyName('Opengraphio');
  //   // props.getLoggedInUser();
  // }, [])

  useEffect(() => {
    if(props.user) {
      setDisplayLoginModal(false);
    }
  }, [props.user]);

  useEffect(() => {
    if(!props.plan.id){
      props.setDebuggerFormAlert('no_account')
    } else {
      if(props.plan.apiKeys.length === 0){
        props.setDebuggerFormAlert('no_plan')
      } else {
        props.setDebuggerFormAlert('sweet');
      }
    }

    if(!props.currentOrganization.tier.data.featuresAllowed){
      setFeaturesAllowed(false)
    }

    if(props.plan && props.plan.id && props.currentOrganization.tier.data && props.currentOrganization.tier.data.featuresAllowed){
      let featuresAllowed = props.currentOrganization.tier.data.featuresAllowed
      setFeaturesAllowed(featuresAllowed)
    }

  }, [props.plan, props.currentOrganization.tier.data])

  useEffect(() => {
    if(props.plan && props.plan.tierId && props.api && props.api.tiers){
      setCurrentPlan(props.api.tiers.find((tier) => tier.id === props.plan.tierId))
    }

  }, [props.plan, props.api])



  const getPlan = () => {
    if(props.user && props.api && props.api.id && props.plan && !props.plan.id && !props.planError ){
      props.getPlanByApiAndOrganization();
    }
  }

  const renderAlert = () => {
    if(props.debuggerForm.alert === 'no_account'){
      return (
        <Alert color={'danger'} className="alert--bordered" isOpen={!alertDismissed} toggle={() => setAlertDismissed(true)}>
          <div className={'padding5'}>
                    <span className={'largeFont'}>
                        <strong>Hold On! </strong>
                        You must <span className={'ogTextColor clickable'} onClick={() => props.history.push('/login')}>sign in</span> or <span className={'ogTextColor clickable'} onClick={() => props.history.push('/register')}>sign up</span> for an account to use the debug tool!
                    </span>
          </div>
        </Alert>
      );
    } else if (props.debuggerForm.alert === 'no_plan'){
      return (
        <Alert color={'danger'} className="alert--bordered" isOpen={!alertDismissed} toggle={() => setAlertDismissed(true)}>
          <div className={'padding5'}>
                    <span>
                        You have an account but no API keys yet, please <span className={'ogBlueText clickable'} onClick={() => props.history.push('/dashboard')}>create one</span> on the dashboard!
                    </span>
          </div>
        </Alert>
      );
    } else if (debuggerForm.alert === 'sweet'){
      return (
        <Alert color={'success'} className="alert--bordered" isOpen={!alertDismissed} toggle={() => setAlertDismissed(true)}>
          <div className={'padding5'}>
            <span >{`Sweet! Using your API key: ${props.plan.apiKeys[0].key}`}</span>
          </div>
        </Alert>
      );
    }

  };

  const renderResults = () => {
      if(debuggerForm.loading){
        return (
          <div className={'loading centerText width100'}>
            <br />
            <div className="load__icon-wrap">
              <svg className="load__icon">
                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
              </svg>
            </div>
            <br />
          </div>
        )
      } else if (debuggerForm.site.results && debuggerForm.apiTabValue === 'site'){
        return (
          <Col md={12} xl={12} >
            <h3 style={{ fontSize: '40px', fontWeight: '700'}} ref={apiPlayGroundResults}>Results</h3>
            <Nav className={'tab--container'} tabs>
              <NavItem className={'clickable'}>
                <NavLink
                  className={debuggerForm.site.tab === 'results' ? 'tabActive nav-link' : 'nav-link'}
                  onClick={() => props.setApiResponseTabValue('results') }
                >
                  OpenGraph Results
                </NavLink>
              </NavItem>
              <NavItem className={'clickable'}>
                <NavLink
                  className={debuggerForm.site.tab === 'details' ? 'tabActive nav-link' : 'nav-link'}
                  onClick={() => props.setApiResponseTabValue('details') }
                >
                  Api Details
                </NavLink>
              </NavItem>
              <NavItem className={'clickable'}>
                <NavLink
                  className={debuggerForm.site.tab === 'react' ? 'tabActive nav-link' : 'nav-link'}
                  onClick={() => props.setApiResponseTabValue('react') }
                >
                  React Component Preview
                </NavLink>
              </NavItem>
              <NavItem className={'clickable'}>
                <NavLink
                    className={debuggerForm.site.tab === 'react' ? 'tabActive nav-link' : 'nav-link'}
                    onClick={() => props.setApiResponseTabValue('snippet') }
                >
                  Copy Snippet
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={debuggerForm.site.tab}>
              <TabPane tabId="results">
                <Card>
                  <CardBody>
                    <DebugResults results={debuggerForm.site.results}/>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="details">
                <Card>
                  <CardBody>
                    <DebugApiResults results={debuggerForm.site.results} lastCalledUrl={debuggerForm.site.lastCalledUrl}/>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="react">
                <Card>
                  <CardBody>
                    <ReactPreview results={debuggerForm.site.results}/>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="snippet">
                <Card>
                  <CardBody>
                    <DebugRequestSnippet />
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>

          </Col>
        )
      } else if(debuggerForm.extract.results && debuggerForm.apiTabValue === 'extract') {
        return (
          <Col md={12} xl={12} >
            <h3 style={{ fontSize: '40px', fontWeight: '700'}} ref={apiPlayGroundResults}>Results</h3>
            <div>
              <DebugApiResults results={debuggerForm.extract.results} lastCalledUrl={debuggerForm.extract.lastCalledUrl}/>
            </div>
          </Col>
        )
      } else if(debuggerForm.scrape.results && debuggerForm.apiTabValue === 'scrape') {
        return (
            <Col md={12} xl={12} >
              <h3 style={{ fontSize: '40px', fontWeight: '700'}} ref={apiPlayGroundResults}>Results</h3>
              <div>
                <DebugApiResults results={debuggerForm.scrape.results} html lastCalledUrl={debuggerForm.scrape.lastCalledUrl}/>
              </div>
            </Col>
        )
      } else if(debuggerForm.oembed.results && debuggerForm.apiTabValue === 'oembed') {
        return (
            <OembedResults results={debuggerForm.oembed.results} playgroundRef={apiPlayGroundResults} />
        )
      } else if(debuggerForm.screenshot.results && debuggerForm.apiTabValue === 'screenshot') {
        return (
          <Col md={12} xl={12} >
            <h3 style={{ fontSize: '40px', fontWeight: '700'}} ref={apiPlayGroundResults}>Results</h3>
            <Nav className={'tab--container'} tabs>
              <NavItem className={'clickable'}>
                <NavLink
                  className={debuggerForm.screenshot.tab === 'screenshot-preview' ? 'tabActive nav-link' : 'nav-link'}
                  onClick={() => props.setApiResponseTabValue('screenshot-preview') }
                >
                  Screenshot Preview
                </NavLink>
              </NavItem>
              <NavItem className={'clickable'}>
                <NavLink
                  className={debuggerForm.screenshot.tab === 'response' ? 'tabActive nav-link' : 'nav-link'}
                  onClick={() => props.setApiResponseTabValue('response') }
                >
                  Api Response
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={debuggerForm.screenshot.tab}>
              <TabPane tabId="screenshot-preview">
                <Card>
                  <CardBody>
                    <ScreenshotPreview imgSrc={debuggerForm.screenshot.results.screenshotUrl}/>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="response">
                <Card>
                  <CardBody>
                    <DebugApiResults results={debuggerForm.screenshot.results} lastCalledUrl={debuggerForm.screenshot.lastCalledUrl}/>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        )
      } else {
        return null
      }
  };
  getPlan()

  return (
    <Container className="dashboard">
      {displayLoginModal ? <LoginModal userState={props.userState} visible={displayLoginModal} formData={{ formData: props.formData }}/> : '' }
      <RateLimitUpgradeModal />
      <Row>
        <Col md={12} xl={12} >
          <div className="form__debugger-group-header">
            <h2 className={'debug--h2'}>OpenGraph.io API Playground</h2>
            { renderAlert() }
          </div>
          <p style={{ marginBottom: '25px'}}>Powered by the greatest OpenGraph API the world has ever seen. Check it out! <a href={'https://www.opengraph.io/documentation'} target="_blank">Documentation Here</a></p>
          <Nav tabs style={{borderBottom: 'none'}}>
            <NavItem>
              <NavLink
                className={debuggerForm.apiTabValue === 'site' ? 'form__debugger-tab active-tab' : 'form__debugger-tab'}
                onClick={() => props.setApiTabValue('site') }
              >
               Open Graph
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={debuggerForm.apiTabValue === 'extract' ? 'form__debugger-tab active-tab' : 'form__debugger-tab'}
                onClick={() => props.setApiTabValue('extract') }
              >
                Extract Site
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={debuggerForm.apiTabValue === 'scrape' ? 'form__debugger-tab active-tab' : 'form__debugger-tab'}
                onClick={() => props.setApiTabValue('scrape') }
              >
                Scrape Site
              </NavLink>
          </NavItem>
            <NavItem>
              <NavLink
                  className={debuggerForm.apiTabValue === 'oembed' ? 'form__debugger-tab active-tab' : 'form__debugger-tab'}
                  onClick={() => props.setApiTabValue('oembed') }
              >
                Embed
              </NavLink>
            </NavItem>
            {currentPlan && !featuresAllowed ? <Tooltip
              target="screenshot-tab"
              isOpen={screenshotTooltipOpen}
              toggle={() => setScreenshotTooltipOpen(!screenshotTooltipOpen)}>
              Unlock this feature by upgrading to one of our new plans!
            </Tooltip> : null}
            <NavItem id={'screenshot-tab'} >
              <NavLink
                disabled={currentPlan && !featuresAllowed }
                className={debuggerForm.apiTabValue === 'screenshot' ? 'form__debugger-tab active-tab' : 'form__debugger-tab'}
                onClick={() => props.setApiTabValue('screenshot') }
              >
                Screenshot <span style={{fontSize: '12px'}}  className={'debugger-credit--amount'}>New</span>
              </NavLink>
            </NavItem>
          </Nav>
          <br />
          <TabContent activeTab={debuggerForm.apiTabValue}>
            <TabPane tabId="site">
              <Col md={12} xl={12} className="debugger-from--wrapper">
                <Card>
                  <CardBody>
                <Form>
                    <BaseApiCallForm
                      buttonTitle={'Submit'}
                      disabled={!props.plan.id}
                      documentaionAnchor={'site'}
                      featuresAllowed={featuresAllowed}
                      showUpgradeTooltip={ currentPlan && !featuresAllowed }
                      tab={'site'}
                    />
                </Form>
                  </CardBody>
                </Card>
              </Col>
            </TabPane>
            <TabPane tabId="extract">
              <Col md={12} xl={12} className="debugger-from--wrapper">
                <Card>
                  <CardBody>
                    <Form>
                      <BaseApiCallForm
                        buttonTitle={'Extract Site Info'}
                        disabled={!props.plan.id}
                        documentaionAnchor={'extract'}
                        featuresAllowed={featuresAllowed}
                        hideCacheUpdate
                        showUpgradeTooltip={currentPlan && !featuresAllowed}
                        tab={'extract'}
                      />
                      <ExtractForm
                        toggleHtmlElement={props.handleHtmlElementChange}
                        extractOptions={debuggerForm.extract.options}
                        disabled={!props.plan.id}/>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </TabPane>
            <TabPane tabId="scrape">
              <Col md={12} xl={12} className="debugger-from--wrapper">
                <Card>
                  <CardBody>
                    <Form>
                      <BaseApiCallForm
                        buttonTitle={'Scrape Site Info'}
                        disabled={!props.plan.id}
                        documentaionAnchor={'scrape'}
                        featuresAllowed={featuresAllowed}
                        showUpgradeTooltip={currentPlan && !featuresAllowed}
                        tab={'scrape'}
                        hideCacheUpdate
                      />
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </TabPane>
            <TabPane tabId="screenshot">
              <Col md={12} xl={12} className="debugger-from--wrapper">
                <Card>
                  <CardBody>
                    <Form>
                      <BaseApiCallForm
                        buttonTitle={'Get Site Screenshot'}
                        disabled={!props.plan.id}
                        documentaionAnchor={'screenshot'}
                        tab={'screenshot'}
                        featuresAllowed={featuresAllowed}
                        showUpgradeTooltip={currentPlan && !featuresAllowed }
                        hideProxy
                        hideAutoProxy
                        hideFullRender
                      />
                      <ScreenshotForm />
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </TabPane>
            <TabPane tabId="oembed">
              <Col md={12} xl={12} className="debugger-from--wrapper">
                <Card>
                  <CardBody>
                    <Form>
                      <BaseApiCallForm
                          buttonTitle={'Get Site Embed'}
                          disabled={!props.plan.id}
                          documentaionAnchor={'oembed'}
                          tab={'embed'}
                          featuresAllowed={featuresAllowed}
                          showUpgradeTooltip={currentPlan && !featuresAllowed }
                      />
                      <EmbedForm />
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
      <Row>
        {renderResults()}
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  user: state.users.user,
  userState: state.users,
  plan: state.plans.detailPlan,
  planError: state.plans.error,
  api: state.apis.detailApi,
  debuggerForm: state.debuggerForm,
  formData: state.form.log_in_form,
  currentOrganization: state.organization.currentOrg
});

const mapDispatchToProps = (dispatch) => ({
  getLoggedInUser: () => dispatch(getLoggedInUser()),
  getPlanByApiAndOrganization: (() => dispatch(getPlanByApiAndOrganization())),
  getApiByFriendlyName: (payload) => dispatch(getApiByFriendlyName(payload)),
  setApiTabValue: (payload) => dispatch(setApiTabValue(payload)),
  handleHtmlElementChange: (event) => dispatch(handleHtmlElementChange(event)),
  setDebuggerFormAlert: (payload) => dispatch(setDebuggerFormAlert(payload)),
  setApiResponseTabValue: (payload) => dispatch(setApiResponseTabValue(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DebugTool)
