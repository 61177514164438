import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeProps } from '../../shared/prop-types/ReducerProps';
import { getLoggedInUser } from '../../redux/actions/users';
import { withRouter } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { TrackGoogleAnalyticsEvent } from '../../shared/utils/google-analytics';
import { getCurrentDashboardViewFromStorage } from '../../redux/actions/preferences';

const MainWrapper = ({ theme, children, getLoggedInUser, user, userLoading, preferences }) => {

  const [ appLoading, setAppLoading ] = React.useState(true);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const fetchUser = async () => {
     await getLoggedInUser();
     setAppLoading(false)
    }

    if(location.search.includes('emailVerified=true')) {
      TrackGoogleAnalyticsEvent('verifyEmail', 'Sign Up Flow', 'verifyEmail', 'Email Verified')
      // then remove the query string
      history.push(location.pathname);
    }
    if(user) return;

    fetchUser();
  }, []);

  useEffect(() => {
    if(user) {
      const intercomUser = {
        app_id: 'm374hgng',
        email: user.email
      }

      window.Intercom('boot', intercomUser);
    } else if(!user && !userLoading) {
      history.push('/login');
    }
  }, [ user ]);

  useEffect(() => {
    if (!user) {
      return;
    }

    // Check if there's a stored view in localStorage first
    const storedView = getCurrentDashboardViewFromStorage();
    const currentView = storedView || preferences.dashboard.view;
    
    if(preferences.dashboard.switch) {
      switch (currentView) {
        case 'DEVELOPER':
          history.push('/apis/Opengraphio');
          break;
        case 'MARKETER':
          history.push('/link-preview');
          break;
      }
    }
  }, [preferences.dashboard.switch]);

  return (
    <div className={theme.className}>
      <div className="wrapper">
        {/*{ appLoading ? (<div className="spinner-border text-success" role="status">*/}
        {/*    <span className="sr-only">Loading...</span>*/}
        {/*  </div> ) : children }*/}
        {children}
      </div>
    </div>
  );
};

MainWrapper.propTypes = {
  theme: ThemeProps.isRequired,
  children: PropTypes.element.isRequired,
  getLoggedInUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.users.user,
  preferences: state.preferences,
  userLoading: state.users.loading
});

const mapDispatchToProps = (dispatch) => ({
  getLoggedInUser: () => dispatch(getLoggedInUser()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainWrapper));
