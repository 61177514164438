import React from 'react';
import { Button, CardBody, Col } from 'reactstrap';
import {
  setSelectedDesign,
  toggleImageLayoutModal,
  toggleSpecificImageLayoutModal
} from '../../../../redux/actions/linkPreview';
import { connect } from 'react-redux';

const OrganizationDesigns = (props) => {
  //Dispatch Props Below
  const { toggleImageLayoutModal, setSelectedDesign } = props;

  //State Props Below
  const { imageLayouts, imageDesigns } = props;

  const renderCreateLayoutCard = (layout) => (
    <CardBody style={{ borderRadius: '20px', marginBottom: '20px'}}>
      <Col md={12}>
        <div className={'new-design-card'}>
          <img
            src={layout.imageUrl}
            alt={layout.alt || layout.name}
            className="new-design-image-preview"
          />
          <div className={'new-design-card-info'}>
            <h4>New Design</h4>
          </div>
          <Button className={'new-design-card-cta'} onClick={() => toggleImageLayoutModal(true)} color={'primary'}>Create New Design</Button>
        </div>
      </Col>
    </CardBody>
  );

  const imageUrl = (layoutId) => {
    let layout = imageLayouts.layouts.find(layout => layout.id === layoutId);
    return layout ? layout.imageUrl : '';

  }

  return (
    <div>
      {imageLayouts.layouts[0] ? renderCreateLayoutCard(imageLayouts.layouts[0]) : null}
      <CardBody style={{ borderRadius: '20px', marginBottom: '50px'}}>
        <div className={'image-layout_container'}>
          {imageDesigns.designs.length > 0 ? imageDesigns.designs.map((design, i) => (
            <CardBody style={{ borderRadius: '20px', padding: '20px', flex: 'unset', cursor: 'pointer'}} onClick={() => setSelectedDesign(design)}>
            <div
              // onClick={() => handleLayoutSelect(layout)}
              key={`layout-${i}`}
              className="image-layout_card"
            >
              <img src={imageUrl(design.layoutId)} alt={design.alt || design.name || `Layout-${i}`} className="new-design-image-preview" />
              <p>{design.name}</p>
            </div>
            </CardBody>
          )) : <p>No designs found</p>}
        </div>
      </CardBody>
    </div>
  )
}


const mapStateToProps = (state) => ({
  imageLayouts: state.linkPreview.imageLayouts,
  imageDesigns: state.linkPreview.imageDesigns,
});

const mapDispatchToProps = (dispatch) => ({
  toggleImageLayoutModal: (open) => dispatch(toggleImageLayoutModal(open)),
  toggleSpecificImageLayoutModal: (open, layout) => dispatch(toggleSpecificImageLayoutModal(open, layout)),
  setSelectedDesign: (images) => dispatch(setSelectedDesign(images))
})


export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDesigns);
