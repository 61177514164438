import React, { useEffect } from 'react';
import { Button, CardBody, Col, Spinner } from 'reactstrap';
import { 
  toggleImageLayoutModal, 
  toggleSpecificImageLayoutModal, 
  loadMoreDesignParameters 
} from '../../../../redux/actions/linkPreview';
import moment from 'moment';
import { connect } from 'react-redux';
import { PiCopySimple } from 'react-icons/pi';
import { FaArrowLeft } from 'react-icons/fa';
import { BsFileEarmarkText } from 'react-icons/bs';
import TablePagination from '../../../../shared/TablePagination';

const OrganizationImages = (props) => {
  const [ copied, setCopied ] = React.useState(false);
  const [ selectedLayout, setSelectedLayout ] = React.useState({imageUrl: ''});
  const [ showDocumentation, setShowDocumentation ] = React.useState(false);
  const [ copiedItemId, setCopiedItemId ] = React.useState(null);
  const [ currentPage, setCurrentPage ] = React.useState(1);

  //Dispatch Props Below
  const { toggleSpecificImageLayoutModal, loadMoreDesignParameters } = props;

  //State Props Below
  const { selectedDesign, imageLayouts, isLoading } = props;

  useEffect(() => {
    if (selectedDesign && selectedDesign.layoutId) {
      const layout = imageLayouts.layouts.find(layout => layout.id === selectedDesign.layoutId);
      if (layout) {
        setSelectedLayout(layout);
      }
    }
  }, [selectedDesign, imageLayouts.layouts]);

  // Add error handling for selected design not being available
  const isValidDesign = selectedDesign && selectedDesign.id && selectedDesign.name;

  const copyUrlToClipboard = (url, id) => {
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setCopiedItemId(id);
      setTimeout(() => {
        setCopied(false);
        setCopiedItemId(null);
      }, 1000); // Reset after 1 second
    }, (err) => {
      alert(err);
    });
  }

  const handlePageChange = (page) => {
    if (selectedDesign && selectedDesign.id) {
      setCurrentPage(page + 1);
      loadMoreDesignParameters(selectedDesign.id, page + 1);
    }
  };

  const renderCreateImageCard = () => {
    if (!isValidDesign) {
      return (
        <CardBody style={{ borderRadius: '20px', marginBottom: '20px', padding: '15px' }}>
          <div style={{ textAlign: 'center', padding: '20px 0' }}>
            <p>Select a design to view details</p>
          </div>
        </CardBody>
      );
    }
    
    return (
      <CardBody style={{ borderRadius: '20px', marginBottom: '20px', padding: '15px' }}>
        <div className="new-design-card">
          <img
            src={selectedLayout.imageUrl || ''}
            alt={selectedDesign.alt || selectedDesign.name}
            className="new-design-image-preview"
          />
          <div className="new-design-card-info">
            <h4>{selectedDesign.name}</h4>
            <p>Design ID:</p>
            <div className="your-api-key_key-display">
              <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{selectedDesign.id}</p>
              <span className="your-api-key_key-display_copy">
                <div className="copy-text">
                  <p>{copied && copiedItemId === 'design-id' ? '👍' : 'Copy'}</p>
                </div>
                <PiCopySimple 
                  className="your-api-key_key-display_copy-icon" 
                  onClick={() => copyUrlToClipboard(selectedDesign.id, 'design-id')} 
                  size={24}
                />
              </span>
            </div>
          </div>
          <Button 
            className="new-design-card-cta" 
            onClick={() => toggleSpecificImageLayoutModal(true, selectedLayout)} 
            color="primary"
            disabled={!selectedLayout || !selectedLayout.id}
          >
            Render New Image
          </Button>
        </div>
      </CardBody>
    );
  };

  const renderDocumentation = () => (
    <CardBody style={{ borderRadius: '20px', marginBottom: '50px', padding: '15px' }}>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        marginBottom: '20px', 
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: '10px'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button 
            style={{ 
              background: 'transparent', 
              border: 'none', 
              color: '#333', 
              marginRight: '15px', 
              padding: '5px' 
            }} 
            onClick={() => setShowDocumentation(false)}
          >
            <FaArrowLeft size={16} />
          </Button>
          <h3 style={{ margin: 0, fontWeight: '600', fontSize: 'clamp(18px, 4vw, 24px)' }}>Dynamic Image Rendering</h3>
        </div>
      </div>

      <div style={{ backgroundColor: '#f9f9f9', padding: '15px', borderRadius: '8px', marginBottom: '20px' }}>
        <h4 style={{ borderBottom: '1px solid #eee', paddingBottom: '10px', marginBottom: '15px', fontSize: 'clamp(16px, 3vw, 20px)' }}>How to Use Dynamic Images</h4>
        <p>Dynamic images allow you to create and update images on-the-fly by specifying parameter values in the URL.</p>
        
        <h5 style={{ marginTop: '20px', fontWeight: '600' }}>URL Structure</h5>
        <div style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '4px', overflowX: 'auto', marginBottom: '15px' }}>
          <code style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>{'https://cdn.opengraph.io/thumbnail/{designId}/{imageUrl}/{title}/{siteText}/{ctaText}/image.png'}</code>
        </div>
        
        <h5 style={{ marginTop: '20px', fontWeight: '600' }}>Parameters</h5>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li style={{ marginBottom: '10px' }}>
            <span style={{ fontWeight: '600', color: '#2CBD6B' }}>designId</span>: Your design template ID
          </li>
          <li style={{ marginBottom: '10px' }}>
            <span style={{ fontWeight: '600', color: '#2CBD6B' }}>imageUrl</span>: URL-encoded background image
          </li>
          <li style={{ marginBottom: '10px' }}>
            <span style={{ fontWeight: '600', color: '#2CBD6B' }}>title</span>: Main headline text
          </li>
          <li style={{ marginBottom: '10px' }}>
            <span style={{ fontWeight: '600', color: '#2CBD6B' }}>siteText</span>: Secondary text (usually site name)
          </li>
          <li style={{ marginBottom: '10px' }}>
            <span style={{ fontWeight: '600', color: '#2CBD6B' }}>ctaText</span>: Call-to-action button text
          </li>
        </ul>
      </div>

      <div style={{ backgroundColor: '#f9f9f9', padding: '15px', borderRadius: '8px' }}>
        <h4 style={{ borderBottom: '1px solid #eee', paddingBottom: '10px', marginBottom: '15px', fontSize: 'clamp(16px, 3vw, 20px)' }}>Example</h4>
        
        <div style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '4px', overflowX: 'auto', marginBottom: '15px' }}>
          <code style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap' }}>{'https://cdn.opengraph.io/thumbnail/123456/https%3A%2F%2Fexample.com%2Fimage.jpg/Check%20out%20this%20great%20product/OpenGraph.io/Learn%20More/image.png'}</code>
        </div>
        
        <p>This will create an image with:</p>
        <ul style={{ listStyleType: 'none', padding: 0, marginBottom: '20px' }}>
          <li style={{ marginBottom: '5px' }}>• Design template ID: 123456</li>
          <li style={{ marginBottom: '5px' }}>• Background image: https://example.com/image.jpg</li>
          <li style={{ marginBottom: '5px' }}>• Title: "Check out this great product"</li>
          <li style={{ marginBottom: '5px' }}>• Site text: "OpenGraph.io"</li>
          <li style={{ marginBottom: '5px' }}>• CTA text: "Learn More"</li>
        </ul>
        
        <h5 style={{ marginTop: '20px', fontWeight: '600' }}>Using in Meta Tags</h5>
        <p>To include your dynamic image in Open Graph meta tags, add the following to your HTML:</p>
        
        <div style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '4px', overflowX: 'auto', marginBottom: '15px' }}>
          <pre style={{ wordBreak: 'break-all', whiteSpace: 'pre-wrap', fontSize: 'clamp(12px, 2vw, 14px)' }}>{`<!-- Open Graph Meta Tags -->
<meta property="og:image" content="https://cdn.opengraph.io/thumbnail/123456/https%3A%2F%2Fexample.com%2Fimage.jpg/Check%20out%20this%20great%20product/OpenGraph.io/Learn%20More/image.png" />
<meta property="og:image:width" content="1200" />
<meta property="og:image:height" content="630" />

<!-- Twitter Card Meta Tags -->
<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:image" content="https://cdn.opengraph.io/thumbnail/123456/https%3A%2F%2Fexample.com%2Fimage.jpg/Check%20out%20this%20great%20product/OpenGraph.io/Learn%20More/image.png" />`}</pre>
        </div>
        
        <p style={{ marginTop: '15px' }}>
          <strong>Note:</strong> Always URL-encode parameter values to ensure proper rendering.
        </p>
      </div>
    </CardBody>
  );

  const renderLoadingState = () => (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center',
      alignItems: 'center',
      padding: '50px 0',
      flexDirection: 'column'
    }}>
      <Spinner color="primary" style={{ marginBottom: '15px' }} />
      <p>Loading design data...</p>
    </div>
  );

  const renderImagesTable = () => (
    <CardBody style={{ borderRadius: '20px', marginBottom: '50px', padding: '15px' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '15px', flexWrap: 'wrap' }}>
        <Button
          color="primary"
          outline
          style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
          onClick={() => setShowDocumentation(true)}
        >
          <BsFileEarmarkText size={14} />
          Dynamic Image Documentation
        </Button>
      </div>
      <div className="responsive-table-container">
        <table className="responsive-table">
          <thead>
            <tr style={{ color: 'rgba(160, 171, 187, 1)'}}>
              <th style={{borderBottom: '2px solid rgba(244, 244, 244, 1)', paddingBottom: '10px', width: '30%'}}>Image</th>
              <th style={{borderBottom: '2px solid rgba(244, 244, 244, 1)', paddingBottom: '10px', width: '50%'}}>URL</th>
              <th style={{borderBottom: '2px solid rgba(244, 244, 244, 1)', paddingBottom: '10px', width: '20%', paddingLeft: '10px'}}>Created Date</th>
            </tr>
          </thead>
          <tbody>
          {selectedDesign.DesignParameters && selectedDesign.DesignParameters.length > 0 ? (
            selectedDesign.DesignParameters.map((image, i) => (
              <tr key={`design-image-${i}`}>
                <td>
                  <img src={image.cdnUrl} alt={image.values.title || `design-image-${i}`} className="new-design-image-preview" />
                </td>
                <td>
                  <div className="your-api-key_key-display">
                    <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{image.cdnUrl}</p>
                    <span className="your-api-key_key-display_copy">
                      <div className="copy-text">
                        <p>{copied && copiedItemId === `image-${i}` ? '👍' : 'Copy'}</p>
                      </div>
                      <PiCopySimple 
                        className="your-api-key_key-display_copy-icon" 
                        onClick={() => copyUrlToClipboard(image.cdnUrl, `image-${i}`)} 
                        size={24}
                      />
                    </span>
                  </div>
                </td>
                <td style={{ paddingLeft: '10px'}}>
                  <p>{moment(image.createdAt).fromNow()}</p>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" style={{ textAlign: 'center', padding: '30px 0' }}>
                No images found for this design.
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
      {selectedDesign && selectedDesign.pagination && selectedDesign.pagination.total > 0 && (
        <div style={{ marginTop: '20px' }}>
          <TablePagination 
            count={selectedDesign.pagination.total} 
            itemsPerPage={selectedDesign.pagination.limit} 
            currentPage={currentPage - 1}
            changePage={handlePageChange}
          />
        </div>
      )}
    </CardBody>
  );

  return (
    <div>
      {renderCreateImageCard()}
      {showDocumentation ? (
        renderDocumentation()
      ) : (
        isLoading || !isValidDesign ? renderLoadingState() : renderImagesTable()
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedDesign: state.linkPreview.selectedDesign.design,
  imageLayouts: state.linkPreview.imageLayouts,
  isLoading: state.linkPreview.selectedDesign.loading,
});

const mapDispatchToProps = (dispatch) => ({
  toggleImageLayoutModal: (open) => dispatch(toggleImageLayoutModal(open)),
  toggleSpecificImageLayoutModal: (open, layout) => dispatch(toggleSpecificImageLayoutModal(open, layout)),
  loadMoreDesignParameters: (designId, page, limit) => dispatch(loadMoreDesignParameters(designId, page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationImages);
