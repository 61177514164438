import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal,  ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { setDebuggerRateLimitAlert } from '../../../../redux/actions/debuggerForm';
import './RateLimitUpgradeModal.scss';

const RateLimitUpgradeModal = ({ isVisible, dismissModal, source }) => {
  const getTitle = () => {
    return source === 'linkPreview' 
      ? 'Link Preview Limit Exceeded' 
      : 'Plan Request Limit Exceeded';
  };

  const getMessage = () => {
    return source === 'linkPreview'
      ? 'You have reached your Link Preview limit. Upgrade your plan to continue creating link previews with higher limits.'
      : 'You have reached your API request limit. Upgrade your plan to continue using the API with higher request limits.';
  };

  return (
    <Modal 
      isOpen={isVisible} 
      toggle={() => dismissModal(false)} 
      className="rate-limit-modal"
      size="lg"
    >
      <button 
        className="close-button" 
        onClick={() => dismissModal(false)}
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
      <ModalBody className="modal-body-unlock p-0">
        <div className="unlock-container">
          <div className="image-container">
            <img src="/img/woman-at-laptop.svg" alt="Woman using laptop" className="illustration" />
          </div>
          <div className="content-container">
            <h2 className="unlock-title">{getTitle()}</h2>
            <p className="unlock-message">
                {getMessage()}
            </p>
            
            <Link to="/apis/billing/Opengraphio" style={{ width: '100%', textDecoration: 'none' }}>
              <Button 
                color="primary" 
                block
                onClick={() => dismissModal(false)}
                className="next-button"
              >
                Upgrade Now
              </Button>
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isVisible: state.debuggerForm.rateLimitAlert,
  source: state.debuggerForm.rateLimitSource
});

const mapDispatchToProps = (dispatch) => ({
  dismissModal: (payload) => dispatch(setDebuggerRateLimitAlert(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(RateLimitUpgradeModal); 