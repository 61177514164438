import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'reactstrap';

import './ImageTemplateModal.css';
import { IoClose } from 'react-icons/io5';
import { IoWarning } from 'react-icons/io5';
import {
  setModalSelectedLayout, 
  setModalStep, 
  toggleImageLayoutModal, 
  getImageLayouts, 
  setModalIsDesignMode, 
  updateModalFormField, 
  resetModalFormFields, 
  createDesign,
  createImage,
  clearDesignError
} from '../../../../redux/actions/linkPreview';
import { connect } from 'react-redux';

const ImageTemplateModal = (props) => {
  const { 
    imageLayoutModal, 
    toggleImageLayoutModal: toggle, 
    imageLayouts, 
    getImageLayouts, 
    setModalStep, 
    setModalSelectedLayout, 
    setModalIsDesignMode,
    updateModalFormField,
    resetModalFormFields,
    createDesign,
    createImage,
    clearDesignError,
    selectedDesign,
    linkPreviewForm
  } = props;
  
  const { step, selectedLayout, isDesignMode, formFields, error } = imageLayoutModal;
  const { title, subtitle, imageUrl, ctaText, name, color } = formFields;
  const { open: visible, loading } = imageLayoutModal;

  const [ showColorPicker, setShowColorPicker ] = useState(true);

  // Reset form fields and clear errors when modal is closed
  useEffect(() => {
    if (!visible) {
      resetModalFormFields();
      // Clear any errors when the modal is closed
      if (error) {
        clearError();
      }
    }
  }, [visible]);

  // Pre-fill form fields with data from LinkPreviewTool when modal opens
  useEffect(() => {
    if (visible && linkPreviewForm) {
      // Only pre-fill the image URL, not title or description
      if (linkPreviewForm.image) {
        updateModalFormField('imageUrl', linkPreviewForm.image);
      }
    }
  }, [visible, linkPreviewForm]);

  const nextStep = () => setModalStep(step + 1);
  const prevStep = () => setModalStep(step - 1);

  const handleFieldChange = (fieldName, value) => {
    updateModalFormField(fieldName, value);
  };

  const handleLayoutSelect = (layout) => {
    setModalSelectedLayout(layout);
    nextStep();
  };

  const toggleColorPicker = () => setShowColorPicker(!showColorPicker);

  // Clear error message
  const clearError = () => {
    // Dispatch action to clear the error
    clearDesignError();
  };

  // Handle the form submission based on the current mode
  const handleSubmit = () => {
    // Clear any previous errors before submitting
    if (error) {
      clearError();
    }

    if (isDesignMode) {
      createDesign();
    } else {
      // When not in design mode, use createImage with the design ID
      const designId = selectedDesign ? selectedDesign.id : null;
      createImage(designId);
    }
  };

  const renderEditableField = ( field ) => {
    switch (field) {
      case 'title':
        return (
          <>
            <label>Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => handleFieldChange('title', e.target.value)}
              className="form-input"
            />
          </>
        );
      case 'siteText':
        return (
          <>
            <label>Site Text</label>
            <textarea
              value={subtitle}
              onChange={(e) => handleFieldChange('subtitle', e.target.value)}
              rows={3}
              className="form-textarea"
            />
          </>
        );
      case 'imageUrl':
        return (
          <>
            <label>Image URL</label>
            <input
              type="text"
              value={imageUrl}
              onChange={(e) => handleFieldChange('imageUrl', e.target.value)}
              className="form-input"
            />
          </>
        );
      case 'ctaText':
        return (
          <>
            <label>CTA Text</label>
            <input
              type="text"
              value={ctaText}
              onChange={(e) => handleFieldChange('ctaText', e.target.value)}
              className="form-input"
            />
          </>
        );
      case 'name':
        return (
          isDesignMode && (
            <>
              <label>Design Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => handleFieldChange('name', e.target.value)}
                className="form-input"
              />
            </>
          )
        );
      case 'color':
        return (
          <div style={{ position: 'relative'}} >
            {/*{showColorPicker ? <div className={'image-template-color-picker_container'}><HexColorPicker color={color} onChange={setColor} /></div> : null}*/}
            <p onClick={toggleColorPicker} className={'input-button'}>{color}</p>
          </div>
        );
      default:
        return null;
    }
  };

  // Error message component
  const ErrorMessage = ({ message }) => (
    <div className="error-message">
      <div className="error-message-icon"><IoWarning /></div>
      <div className="error-message-text">{message}</div>
      <div className="error-message-close" onClick={clearError}>×</div>
    </div>
  );

  // Loading overlay component
  const LoadingOverlay = () => (
    <div className="loading-overlay">
      <div className="loading-spinner">
        <div className="spinner-grow text-success" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if(imageLayouts.layouts.length === 0) {
      getImageLayouts();
    }
  }, []);

  const step1 = (
    <div className={'image-template-modal_step-1_container'}>
      <h3>{isDesignMode ? 'Choose a design template' : 'Choose an image template'}</h3>
      <div className="image-templates-container">
        {imageLayouts.layouts.map((layout, i) => (
          <div
            onClick={() => handleLayoutSelect(layout)}
            key={i}
            className="image-template"
          >
            <img src={layout.imageUrl} alt={layout.alt || `Layout-${i}`} className="template-image" />
          </div>
        ))}
      </div>
    </div>
  );

  const step2 = () => (
    <div className="image-template-modal_step-2_container">
      {/* Form Column */}
      <h3>{isDesignMode ? 'Create Design' : 'Render Image'}</h3>
      <div className={'image-template-modal_step-2_form_container'}>
      <div className="editor-form">
        <div className="form">
        {isDesignMode && (
          <div className="form__form-group">
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => handleFieldChange('name', e.target.value)}
              className="form-input"
            />
          </div>
        )}
          {selectedLayout.params.map((field, i) => (
            <div key={i} className="form__form-group">
              {renderEditableField(field)}
            </div>
          ))}
        </div>
      </div>

      {/* Image Display Column */}
      <div className="image-display">
        {selectedLayout && (
          <img
            src={selectedLayout.imageUrl}
            alt={selectedLayout.alt || selectedLayout.name}
            className="selected-template-image"
          />
        )}
      </div>
      </div>
    </div>
  );

  return (
    <Modal fullscreen isOpen={visible} toggle={() => toggle(false)} className={`image-template-modal ${step === 2 ? 'large' : ''}`}>
      <div className="modal__close-btn" onClick={() => toggle(false)}>
        <IoClose size={26} />
      </div>
      
      {/* Sleeker loading indicator */}
      {loading && <LoadingOverlay />}
      
      {/* Error message display */}
      {error && <ErrorMessage message={error} />}
      
      {step === 1 && step1}
      {step === 2 && step2()}
      <div className={'image-template-modal_button_container'}>
        {step === 2 && <Button color="primary" onClick={prevStep} disabled={loading}>Back</Button>}
        {step === 2 && <Button color="primary" onClick={handleSubmit} disabled={loading}>
          {isDesignMode ? 'Create Design' : 'Render Image'}
        </Button>}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  imageLayouts: state.linkPreview.imageLayouts,
  imageLayoutModal: state.linkPreview.imageLayoutModal,
  selectedDesign: state.linkPreview.selectedDesign.design,
  linkPreviewForm: state.linkPreview.form
});

const mapDispatchToProps = (dispatch) => ({
  toggleImageLayoutModal: (open) => dispatch(toggleImageLayoutModal(open)),
  setModalSelectedLayout: (layout) => dispatch(setModalSelectedLayout(layout)),
  setModalStep: (step) => dispatch(setModalStep(step)),
  getImageLayouts: () => dispatch(getImageLayouts()),
  setModalIsDesignMode: (isDesignMode) => dispatch(setModalIsDesignMode(isDesignMode)),
  updateModalFormField: (fieldName, value) => dispatch(updateModalFormField(fieldName, value)),
  resetModalFormFields: () => dispatch(resetModalFormFields()),
  createDesign: () => dispatch(createDesign()),
  createImage: (designId) => dispatch(createImage(null, designId)),
  clearDesignError: () => dispatch(clearDesignError())
})

export default connect(mapStateToProps, mapDispatchToProps)(ImageTemplateModal);
