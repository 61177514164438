import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

import './TabsStyles.css';

const Tabs = ({active, onTabChange, design}) => {

  return (
    <Nav
      className={'dashboard--tabs_container'}
      pills
    >
      <NavItem>
        <NavLink
        
          className={`dashboard--tab ${ active === 'dashboard' ? 'active-tab' : '' }`}
          onClick={() => onTabChange('dashboard')}
        >
          Link Preview Tool
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={`dashboard--tab ${ active === 'designs' ? 'active-tab' : '' }`}
          onClick={() => onTabChange('designs')}
        >
          Designs
        </NavLink>
      </NavItem>
      {design ? <NavItem>
        <NavLink
          className={`dashboard--tab ${active === 'images' ? 'active-tab' : ''}`}
          onClick={() => onTabChange('images')}
        >
          Images
        </NavLink>
      </NavItem> : null }
    </Nav>
  )
}

export default Tabs;
