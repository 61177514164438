import { apiClient } from '../../constants/clients';
import {removeStripeModal, toggleCancelSubscriptionModal} from './stripeModal';
import {
  UPDATE_STRIPE_SOURCE_FOR_PLAN,
  CLEAR_DETAIL_PLAN,
  CLEAR_PLAN_ALERT,
  CREATE_PLAN,
  CREATE_PLAN_FAIL,
  CREATE_PLAN_SUCCESS,
  GET_PLAN_BY_API_AND_ORGANIZATION,
  GET_PLAN_BY_API_AND_ORGANIZATION_FAIL,
  GET_PLAN_BY_API_AND_ORGANIZATION_SUCCESS,
  UPDATE_STRIPE_SOURCE_FOR_PLAN_FAIL,
  UPDATE_STRIPE_SOURCE_FOR_PLAN_SUCCESS,
  UPDATE_PLAN_FAIL,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN,
  GET_PLANS_FOR_ORGANIZATION,
  GET_PLANS_FOR_ORGANIZATION_FAIL,
  GET_PLANS_FOR_ORGANIZATION_SUCCESS,
  CLEAR_STRIPE_ERROR,
  SET_STRIPE_ERROR
} from '../actionTypes';
import { TrackGoogleAnalyticsEvent } from '../../shared/utils/google-analytics';


export function setStripeError(payload){
  return {type: SET_STRIPE_ERROR, payload}
}

export function getPlanByApiAndOrganization(payload){
  return (dispatch, getState) => {
    payload = payload || {};
    dispatch({type: GET_PLAN_BY_API_AND_ORGANIZATION});
    let user = getState().users.user || {};
    let organizationId = payload.organizationId || user.defaultOrgId;
    let apiId = payload.apiId || getState().apis.detailApi.id;
    apiClient.getPlanByOrganizationAndApi(organizationId, apiId)
      .exec((err, result) => {
        if(err){
          dispatch({type: GET_PLAN_BY_API_AND_ORGANIZATION_FAIL, payload: err})
        } else {
          dispatch({type: GET_PLAN_BY_API_AND_ORGANIZATION_SUCCESS, payload: result})
        }
      })
  }
}

export function createPlan(payload){
  return (dispatch, getState) => {
    let user = getState().users.user || {};
    let organizationId = user.defaultOrgId;
    payload.organizationId = organizationId;
    dispatch({type: CREATE_PLAN})
    apiClient.createPlanForOrganization(payload.organizationId, payload)
      .exec((err, result) => {
        if(err){
          dispatch({type: CREATE_PLAN_FAIL, payload: err})
        } else {
          dispatch({type: CREATE_PLAN_SUCCESS, payload: result})
          dispatch(getPlanByApiAndOrganization())
          dispatch(removeStripeModal())
        }
      })
  }
}

export function updateStripeSourceForPlan(source){

  return (dispatch, getState) => {
    dispatch({type: UPDATE_STRIPE_SOURCE_FOR_PLAN});
    let plan = getState().plans.detailPlan;
    plan.stripeSource = source;
    apiClient.updatePlan(plan)
      .exec((err, result) => {
        if(err){
          dispatch({type: UPDATE_STRIPE_SOURCE_FOR_PLAN_FAIL, payload: err.details.message})
        } else {
          dispatch({type: UPDATE_STRIPE_SOURCE_FOR_PLAN_SUCCESS, payload: result})
        }
      })
  }
}

export function updatePlan(payload){
  return (dispatch, getState) => {
    dispatch({type: UPDATE_PLAN});
    // Check if cancelationReason already exists in the payload (from new modal)
    if (!payload.cancelationReason && getState().form.cancel_form) {
      if(getState().form.cancel_form.values){
        // Get the form values using the new format
        const formValues = getState().form.cancel_form.values;
        
        if (formValues.reason && formValues.explanation !== undefined) {
          // New form format with reason and explanation fields
          payload.cancelationReason = JSON.stringify({
            reason: formValues.reason,
            explanation: formValues.explanation || ''
          });
        } else if (formValues.cancelationReason) {
          // Legacy format - convert to new format
          const reason = formValues.cancelationReason;
          payload.cancelationReason = JSON.stringify({
            reason: reason,
            explanation: ''
          });
        }
      }
    }
    apiClient.updatePlan(payload)
      .exec((err, result) => {
        if(err){
          dispatch({type: UPDATE_PLAN_FAIL, payload: err.details.message})
        } else {
          dispatch({type: UPDATE_PLAN_SUCCESS, payload: result})
          // Close all modals
          dispatch(removeStripeModal())
          
          // Ensure the cancellation modal is also closed
          if (payload.cancelationReason) {
            dispatch(toggleCancelSubscriptionModal(false))
          }
        }
      })
  }
}

export function getPlansForOrganization(){
  return (dispatch, getState) => {
    dispatch({type: GET_PLANS_FOR_ORGANIZATION})
    let user = getState().users.user || {organizations: [{}]};
    let organizationId = user.defaultOrgId;
    apiClient.getPlansForOrganization(organizationId)
      .exec((err, result) => {
        if(err){
          dispatch({type: GET_PLANS_FOR_ORGANIZATION_FAIL, payload: err})
        } else {
          dispatch({type: GET_PLANS_FOR_ORGANIZATION_SUCCESS, payload: result})
        }
      })
  }
}

export function provideCancellationFeedback(payload){
  return (dispatch, getState) => {
    dispatch({type: PROVIDE_CANCELLATION_FEEDBACK, payload})
  }
}

export function clearPlanAlert(){
  return {type: CLEAR_PLAN_ALERT}
}

export function clearDetailPlan(){
  return {type: CLEAR_DETAIL_PLAN}
}

export function clearStripeError(){
  return {type: CLEAR_STRIPE_ERROR}
}
